import { createFileRoute } from "@tanstack/react-router";
import React, { useState } from "react";
import Form from "src/components/base/form";
import { Field, Fieldset, RequiredLabel } from "src/components/base/fieldset";
import { Input } from "src/components/base/input";
import { Button } from "src/components/base/button";
import { Api } from "src/api/api";
import { toast } from "react-toastify";
import WS from "src/api/ws";
import { Heading } from "src/components/base/heading";

/** The properties for {@link RunExampleScript} */
type RunExampleScriptProps = {};

/** Random view to demonstrate the basic API of any script */
export function RunExampleScript(props: RunExampleScriptProps) {
    const [script, setScript] = useState<string>();
    const [user, setUser] = useState("");
    const [logs, setLogs] = useState(Array<string>());
    const [answer, setAnswer] = useState<number>();

    React.useEffect(() => {
        if (script === undefined) return;

        const handleA = WS.addEventListener("message.ScriptLog", ({ script: s, log }) => {
            if (s !== script) return;
            setLogs((logs) => [...logs, log]);
        });
        const handleB = WS.addEventListener("message.ScriptResult", ({ script: s, result: { answer } }) => {
            if (s !== script) return;
            setAnswer(answer);
        });

        return () => {
            WS.removeEventListener(handleA);
            WS.removeEventListener(handleB);
        };
    }, [script]);

    if (script === undefined) {
        return (
            <Form
                onSubmit={() => {
                    Api.internal.scripts.runExample({ user }).then((result) =>
                        result.match(
                            ({ uuid }) => setScript(uuid),
                            (err) => toast.error(err.message),
                        ),
                    );
                }}
            >
                <Fieldset>
                    <Field>
                        <RequiredLabel>Name</RequiredLabel>
                        <Input value={user} onChange={(e) => setUser(e.target.value)} />
                    </Field>
                    <Button type={"submit"}>Run</Button>
                </Fieldset>
            </Form>
        );
    } else {
        return (
            <div>
                <Heading className={"mb-6"}>Logs:</Heading>
                {logs.map((log) => (
                    <p>{log}</p>
                ))}
                <Heading className={"mb-6"}>Result:</Heading>
                {answer && (
                    <p>
                        <b>{answer}</b>
                    </p>
                )}
            </div>
        );
    }
}

export const Route = createFileRoute("/_internal/i/run-example-script")({
    component: RunExampleScript,
});
