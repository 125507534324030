/* tslint:disable */
/* eslint-disable */
/**
 * Unnamed API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiErrorResponse,
  CustomerReadyTask,
  FormResultForNullAndAssignProjectContactsV0Errors,
  GetAllTasksRequest,
  InternalGetReadyTasksResponse,
  InternalReadyTask,
  ListForCustomerReadyTask,
  ListForInternalTask,
  ListForUuid,
} from '../models/index';

export interface AddAssignProjectContactsV0InviteRequest {
    uuid: string;
    uuid2: string;
}

export interface ExportProjectTasksRequest {
    uuid: string;
}

export interface FinishExternalTaskV0Request {
    uuid: string;
}

export interface GetAllTasksOperationRequest {
    GetAllTasksRequest: GetAllTasksRequest;
}

export interface GetAssignProjectContactsV0InvitesRequest {
    uuid: string;
}

export interface GetReadyTaskCustomerRequest {
    uuid: string;
}

export interface GetReadyTaskInternalRequest {
    uuid: string;
}

export interface MakeTaskFinishedRequest {
    uuid: string;
}

export interface MakeTaskReadyRequest {
    uuid: string;
}

export interface RemoveAssignProjectContactsV0InviteRequest {
    uuid: string;
    uuid2: string;
}

export interface SubmitAssignProjectContactsV0Request {
    uuid: string;
}

/**
 * 
 */
export class TasksApi extends runtime.BaseAPI {

    /**
     * Adds a contact to be invited after finishing the `AssignProjectContactsV0` task
     * Adds a contact to be invited after finishing the `AssignProjectContactsV0` task
     */
    async addAssignProjectContactsV0InviteRaw(requestParameters: AddAssignProjectContactsV0InviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling addAssignProjectContactsV0Invite().'
            );
        }

        if (requestParameters['uuid2'] == null) {
            throw new runtime.RequiredError(
                'uuid2',
                'Required parameter "uuid2" was null or undefined when calling addAssignProjectContactsV0Invite().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['uuid2'] != null) {
            queryParameters['uuid'] = requestParameters['uuid2'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/customer/tasks/{uuid}/assign-project-contacts-v0/invites`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Adds a contact to be invited after finishing the `AssignProjectContactsV0` task
     * Adds a contact to be invited after finishing the `AssignProjectContactsV0` task
     */
    async addAssignProjectContactsV0Invite(requestParameters: AddAssignProjectContactsV0InviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.addAssignProjectContactsV0InviteRaw(requestParameters, initOverrides);
    }

    /**
     * Exports a project\'s task graph in the [DOT](https://en.wikipedia.org/wiki/DOT_%28graph_description_language%29) format.  This endpoint can be used to inspect the graph and find issues until proper views are implemented.
     * Exports a project\'s task graph in the [DOT](https://en.wikipedia.org/wiki/DOT_%28graph_description_language%29) format.
     */
    async exportProjectTasksRaw(requestParameters: ExportProjectTasksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling exportProjectTasks().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/admin/tasks/project-graph/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Exports a project\'s task graph in the [DOT](https://en.wikipedia.org/wiki/DOT_%28graph_description_language%29) format.  This endpoint can be used to inspect the graph and find issues until proper views are implemented.
     * Exports a project\'s task graph in the [DOT](https://en.wikipedia.org/wiki/DOT_%28graph_description_language%29) format.
     */
    async exportProjectTasks(requestParameters: ExportProjectTasksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.exportProjectTasksRaw(requestParameters, initOverrides);
    }

    /**
     * Sets an external task as finished
     * Sets an external task as finished
     */
    async finishExternalTaskV0Raw(requestParameters: FinishExternalTaskV0Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling finishExternalTaskV0().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/common/tasks/{uuid}/external-v0`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Sets an external task as finished
     * Sets an external task as finished
     */
    async finishExternalTaskV0(requestParameters: FinishExternalTaskV0Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.finishExternalTaskV0Raw(requestParameters, initOverrides);
    }

    /**
     * Retrieve\'s tasks matching a filter  At least one filter has to be set!
     * Retrieve\'s tasks matching a filter
     */
    async getAllTasksRaw(requestParameters: GetAllTasksOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListForInternalTask>> {
        if (requestParameters['GetAllTasksRequest'] == null) {
            throw new runtime.RequiredError(
                'GetAllTasksRequest',
                'Required parameter "GetAllTasksRequest" was null or undefined when calling getAllTasks().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/internal/tasks/all`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['GetAllTasksRequest'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Retrieve\'s tasks matching a filter  At least one filter has to be set!
     * Retrieve\'s tasks matching a filter
     */
    async getAllTasks(requestParameters: GetAllTasksOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListForInternalTask> {
        const response = await this.getAllTasksRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves the contacts to be invited after finishing the `AssignProjectContactsV0` task
     * Retrieves the contacts to be invited after finishing the `AssignProjectContactsV0` task
     */
    async getAssignProjectContactsV0InvitesRaw(requestParameters: GetAssignProjectContactsV0InvitesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListForUuid>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling getAssignProjectContactsV0Invites().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/customer/tasks/{uuid}/assign-project-contacts-v0/invites`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Retrieves the contacts to be invited after finishing the `AssignProjectContactsV0` task
     * Retrieves the contacts to be invited after finishing the `AssignProjectContactsV0` task
     */
    async getAssignProjectContactsV0Invites(requestParameters: GetAssignProjectContactsV0InvitesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListForUuid> {
        const response = await this.getAssignProjectContactsV0InvitesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getReadyTaskCustomerRaw(requestParameters: GetReadyTaskCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomerReadyTask>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling getReadyTaskCustomer().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/customer/tasks/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async getReadyTaskCustomer(requestParameters: GetReadyTaskCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomerReadyTask> {
        const response = await this.getReadyTaskCustomerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getReadyTaskInternalRaw(requestParameters: GetReadyTaskInternalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InternalReadyTask>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling getReadyTaskInternal().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/internal/tasks/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async getReadyTaskInternal(requestParameters: GetReadyTaskInternalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InternalReadyTask> {
        const response = await this.getReadyTaskInternalRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all ready tasks, i.e. task which are ready to be performed, which are relevant to a customer user
     * Get all ready tasks, i.e. task which are ready to be performed, which are relevant to a customer user
     */
    async getReadyTasksCustomerRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListForCustomerReadyTask>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/customer/tasks/ready`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get all ready tasks, i.e. task which are ready to be performed, which are relevant to a customer user
     * Get all ready tasks, i.e. task which are ready to be performed, which are relevant to a customer user
     */
    async getReadyTasksCustomer(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListForCustomerReadyTask> {
        const response = await this.getReadyTasksCustomerRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get all ready tasks, i.e. task which are ready to be performed, which are relevant to an internal user
     * Get all ready tasks, i.e. task which are ready to be performed, which are relevant to an internal user
     */
    async getReadyTasksInternalRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InternalGetReadyTasksResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/internal/tasks/ready`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get all ready tasks, i.e. task which are ready to be performed, which are relevant to an internal user
     * Get all ready tasks, i.e. task which are ready to be performed, which are relevant to an internal user
     */
    async getReadyTasksInternal(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InternalGetReadyTasksResponse> {
        const response = await this.getReadyTasksInternalRaw(initOverrides);
        return await response.value();
    }

    /**
     * Forces a task to become `Finished` regardless of its current state or dependencies.  This endpoint can be used to hot fix issues in the task graph until better methods are implemented.
     * Forces a task to become `Finished` regardless of its current state or dependencies.
     */
    async makeTaskFinishedRaw(requestParameters: MakeTaskFinishedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling makeTaskFinished().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/admin/tasks/{uuid}/finished`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Forces a task to become `Finished` regardless of its current state or dependencies.  This endpoint can be used to hot fix issues in the task graph until better methods are implemented.
     * Forces a task to become `Finished` regardless of its current state or dependencies.
     */
    async makeTaskFinished(requestParameters: MakeTaskFinishedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.makeTaskFinishedRaw(requestParameters, initOverrides);
    }

    /**
     * Forces a task to become `Ready` regardless of its current state or dependencies.  This endpoint can be used to hot fix issues in the task graph until better methods are implemented.
     * Forces a task to become `Ready` regardless of its current state or dependencies.
     */
    async makeTaskReadyRaw(requestParameters: MakeTaskReadyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling makeTaskReady().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/admin/tasks/{uuid}/ready`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Forces a task to become `Ready` regardless of its current state or dependencies.  This endpoint can be used to hot fix issues in the task graph until better methods are implemented.
     * Forces a task to become `Ready` regardless of its current state or dependencies.
     */
    async makeTaskReady(requestParameters: MakeTaskReadyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.makeTaskReadyRaw(requestParameters, initOverrides);
    }

    /**
     * Removes a contact from being invited after finishing the `AssignProjectContactsV0` task
     * Removes a contact from being invited after finishing the `AssignProjectContactsV0` task
     */
    async removeAssignProjectContactsV0InviteRaw(requestParameters: RemoveAssignProjectContactsV0InviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling removeAssignProjectContactsV0Invite().'
            );
        }

        if (requestParameters['uuid2'] == null) {
            throw new runtime.RequiredError(
                'uuid2',
                'Required parameter "uuid2" was null or undefined when calling removeAssignProjectContactsV0Invite().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['uuid2'] != null) {
            queryParameters['uuid'] = requestParameters['uuid2'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/customer/tasks/{uuid}/assign-project-contacts-v0/invites`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Removes a contact from being invited after finishing the `AssignProjectContactsV0` task
     * Removes a contact from being invited after finishing the `AssignProjectContactsV0` task
     */
    async removeAssignProjectContactsV0Invite(requestParameters: RemoveAssignProjectContactsV0InviteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.removeAssignProjectContactsV0InviteRaw(requestParameters, initOverrides);
    }

    /**
     * Submits a `AssignProjectContactsV0` task as finished
     * Submits a `AssignProjectContactsV0` task as finished
     */
    async submitAssignProjectContactsV0Raw(requestParameters: SubmitAssignProjectContactsV0Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormResultForNullAndAssignProjectContactsV0Errors>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling submitAssignProjectContactsV0().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/customer/tasks/{uuid}/assign-project-contacts-v0/submit`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Submits a `AssignProjectContactsV0` task as finished
     * Submits a `AssignProjectContactsV0` task as finished
     */
    async submitAssignProjectContactsV0(requestParameters: SubmitAssignProjectContactsV0Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormResultForNullAndAssignProjectContactsV0Errors> {
        const response = await this.submitAssignProjectContactsV0Raw(requestParameters, initOverrides);
        return await response.value();
    }

}
