import { createFileRoute } from "@tanstack/react-router";

import React, { useEffect, useState } from "react";
import HeadingLayout from "../../../../components/base/heading-layout";
import { useTranslation } from "react-i18next";
import { Api, UUID } from "../../../../api/api";
import { toast } from "react-toastify";
import { SimpleCustomer } from "../../../../api/generated";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../../../components/base/table";
import { Button } from "../../../../components/base/button";
import { EllipsisVerticalIcon, PlusIcon } from "@heroicons/react/20/solid";
import DialogCreateCustomer from "../../../../components/dialogs/internal/dialog-create-customer";
import { Dropdown, DropdownButton, DropdownItem, DropdownMenu } from "../../../../components/base/dropdown";
import { Dialog, DialogActions, DialogBody, DialogTitle } from "../../../../components/base/dialog";
import UserAvatar from "../../../../components/user-avatar";
import { Field, Label } from "src/components/base/fieldset";
import { Input } from "src/components/base/input";
import USER_CONTEXT from "src/context/user";
import { Checkbox, CheckboxField } from "src/components/base/checkbox";

/**
 * The properties for {@link CustomerManagement}
 */
export type CustomerManagementProps = {};

/**
 * Manage all checkbox states
 */
type CheckboxStates = {
    /** if you are pm toggle to show only your customers */
    showRelevant: boolean;
};

const CHECKBOXES_LS = "internal.customer.checkboxes";

/**
 * Get initial Checkbox state
 *
 * @returns initial checkbox state
 */
const getCheckboxState = () => {
    const checkboxes = localStorage.getItem(CHECKBOXES_LS);

    if (!checkboxes) {
        return undefined;
    }

    const state: CheckboxStates = JSON.parse(checkboxes);
    return state;
};

/**
 * The management view for customers
 */
export default function CustomerManagement(props: CustomerManagementProps) {
    const [t] = useTranslation();
    const [tg] = useTranslation("pm-view");
    const { user } = React.useContext(USER_CONTEXT);

    const [customers, setCustomers] = useState<Array<SimpleCustomer>>([]);

    const [openDeleteCustomerDialog, setOpenDeleteCustomerDialog] = useState<UUID>();
    const [openNewCustomerDialog, setOpenNewCustomerDialog] = useState(false);

    const state = getCheckboxState();
    const [checkboxes, setCheckboxes] = React.useState<CheckboxStates | undefined>(state);

    const [query, setQuery] = React.useState("");

    const filteredCustomer =
        query === "" ? customers : customers.filter((c) => c.legal_name.toLowerCase().includes(query.toLowerCase()));

    /**
     * Update checkbox state in local storage and React
     *
     * @param update new state of checkboxes
     */
    const updateCheckboxState = (update: CheckboxStates) => {
        localStorage.setItem(CHECKBOXES_LS, JSON.stringify(update));
        setCheckboxes(update);
    };

    /** Load the customers from the API */
    const loadCustomers = () => {
        Api.internal.customers.all().then((res) =>
            res.match(
                (list) => setCustomers(list.list),
                (err) => toast.error(err.message),
            ),
        );
    };

    /**
     * Get the name of a customer that matches the uuid
     *
     * @param uuid of the customer
     * @returns name of the customer
     */
    const getCustomerName = (uuid: UUID) => {
        const customer = customers.map((c) => {
            if (c.uuid === uuid) return c;
        });
        if (customer.length === 1 && customer[0] !== undefined) {
            return customer[0].legal_name;
        }
    };

    useEffect(() => {
        if (user.permissions.role === "Internal" && !user.permissions.groups.project_management) {
            updateCheckboxState({
                showRelevant: false,
            });
        } else if (!localStorage.getItem(CHECKBOXES_LS)) {
            updateCheckboxState({
                showRelevant: false,
            });
        }
        loadCustomers();
    }, []);

    if (!checkboxes) {
        return undefined;
    }

    return (
        <HeadingLayout
            heading={t("internal.customer.heading.customer-management")}
            headingChildren={
                <Button onClick={() => setOpenNewCustomerDialog(true)}>
                    <PlusIcon />
                    {t("internal.customer.button.create-customer")}
                </Button>
            }
        >
            <div className={"flex items-end gap-6"}>
                <Field className={"max-w-xs"}>
                    <Label>{t("label.search")}</Label>
                    <Input
                        placeholder={t("label.customer-name")}
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                    />
                </Field>
                {user.permissions.role === "Internal" && user.permissions.groups.project_management && (
                    <CheckboxField>
                        <Checkbox
                            color={"blue"}
                            checked={checkboxes.showRelevant}
                            onChange={(e) =>
                                updateCheckboxState({
                                    ...checkboxes,
                                    showRelevant: e,
                                })
                            }
                        />
                        <Label title={tg("description.own-customer")}>{tg("label.own-customer")}</Label>
                    </CheckboxField>
                )}
            </div>
            <Table dense={true} className={"dark:text-zinc-200"}>
                <TableHead>
                    <TableRow>
                        <TableHeader>
                            <span className={"block text-wrap"}>{t("label.legal-business-name")}</span>
                        </TableHeader>
                        <TableHeader>{t("label.project-manager")}</TableHeader>
                        <TableHeader className={"max-lg:hidden"}>{t("label.created-at")}</TableHeader>
                        <TableHeader className={"w-0"}>
                            <span className={"sr-only"}>{t("label.actions")}</span>
                        </TableHeader>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {filteredCustomer
                        .filter((customer) => {
                            if (checkboxes.showRelevant) {
                                return customer.project_manager.uuid === user.uuid;
                            } else {
                                return true;
                            }
                        })
                        .map((customer) => (
                            <TableRow
                                key={customer.uuid}
                                href={"/i/customers/$customerId/general"}
                                params={{ customerId: customer.uuid }}
                            >
                                <TableCell>{customer.legal_name}</TableCell>
                                <TableCell>
                                    <div className={"flex items-center gap-3"}>
                                        <UserAvatar
                                            className={"size-8"}
                                            user={{
                                                ...customer.project_manager,
                                                name: customer.project_manager.display_name,
                                            }}
                                        />
                                        <span>{customer.project_manager.display_name}</span>
                                    </div>
                                </TableCell>
                                <TableCell className={"max-lg:hidden"}>
                                    {new Date(customer.created_at).toLocaleDateString()}
                                </TableCell>
                                <TableCell>
                                    <Dropdown>
                                        <DropdownButton plain={true}>
                                            <EllipsisVerticalIcon />
                                        </DropdownButton>
                                        <DropdownMenu anchor={"bottom end"}>
                                            <DropdownItem
                                                href={"/i/projects/create"}
                                                search={{ customer: customer.uuid }}
                                            >
                                                {t("button.create-project")}
                                            </DropdownItem>
                                            <DropdownItem onClick={() => setOpenDeleteCustomerDialog(customer.uuid)}>
                                                {t("button.delete")}
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>

            {openNewCustomerDialog && (
                <DialogCreateCustomer
                    open={true}
                    onClose={() => setOpenNewCustomerDialog(false)}
                    onSubmit={() => {
                        setOpenNewCustomerDialog(false);
                        loadCustomers();
                    }}
                />
            )}

            {openDeleteCustomerDialog && (
                <Dialog open={true} onClose={() => setOpenDeleteCustomerDialog(undefined)}>
                    <DialogBody>
                        <DialogTitle>
                            {t("internal.delete-customer.heading", { name: getCustomerName(openDeleteCustomerDialog) })}
                        </DialogTitle>
                        <DialogActions>
                            <Button onClick={() => setOpenDeleteCustomerDialog(undefined)}>{t("button.no")}</Button>
                            <Button
                                color={"red"}
                                onClick={() => {
                                    toast.info("Deleting customers is currently unsupported");
                                    setOpenDeleteCustomerDialog(undefined);
                                }}
                            >
                                {t("button.yes")}
                            </Button>
                        </DialogActions>
                    </DialogBody>
                </Dialog>
            )}
        </HeadingLayout>
    );
}

export const Route = createFileRoute("/_internal/i/customers/")({
    component: CustomerManagement,
});
