/* tslint:disable */
/* eslint-disable */
/**
 * Unnamed API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiErrorResponse,
  SingleUuid,
} from '../models/index';

export interface DownloadScriptResultFileRequest {
    uuid: string;
}

export interface KillScriptRequest {
    uuid: string;
}

export interface RunExampleScriptRequest {
    body: any | null;
}

/**
 * 
 */
export class ScriptsApi extends runtime.BaseAPI {

    /**
     * Download a file produced by a script
     * Download a file produced by a script
     */
    async downloadScriptResultFileRaw(requestParameters: DownloadScriptResultFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling downloadScriptResultFile().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/internal/scripts/file/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Download a file produced by a script
     * Download a file produced by a script
     */
    async downloadScriptResultFile(requestParameters: DownloadScriptResultFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.downloadScriptResultFileRaw(requestParameters, initOverrides);
    }

    /**
     * Kills a running script  This will send a SIGKILL to the OS process. Depending on the script, this might result in broken state.
     * Kills a running script
     */
    async killScriptRaw(requestParameters: KillScriptRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling killScript().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/internal/scripts/{uuid}/kill`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Kills a running script  This will send a SIGKILL to the OS process. Depending on the script, this might result in broken state.
     * Kills a running script
     */
    async killScript(requestParameters: KillScriptRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.killScriptRaw(requestParameters, initOverrides);
    }

    /**
     * Runs the example script  This endpoint is purely for development: - Frontend devs can use it to test the general handling of scripts - Script devs can prototype in the `example.py` and run it
     * Runs the example script
     */
    async runExampleScriptRaw(requestParameters: RunExampleScriptRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SingleUuid>> {
        if (requestParameters['body'] == null) {
            throw new runtime.RequiredError(
                'body',
                'Required parameter "body" was null or undefined when calling runExampleScript().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/internal/scripts/run-example`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Runs the example script  This endpoint is purely for development: - Frontend devs can use it to test the general handling of scripts - Script devs can prototype in the `example.py` and run it
     * Runs the example script
     */
    async runExampleScript(requestParameters: RunExampleScriptRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SingleUuid> {
        const response = await this.runExampleScriptRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
