import { createFileRoute, Outlet } from "@tanstack/react-router";
import TabLayout from "src/components/base/tab-layout";
import { Tab, TabMenu } from "src/components/base/tab-menu";
import React from "react";
import { useTranslation } from "react-i18next";

/**
 * The properties for {@link PmViewLayout}
 */
export type PmViewLayoutProps = {};

/**
 * Overview for Data only relevant for Project Manager
 */
export default function PmViewLayout(props: PmViewLayoutProps) {
    const [t] = useTranslation("pm-view");
    const [tg] = useTranslation();

    return (
        <TabLayout
            heading={tg("label.pm-corner")}
            tabs={
                <TabMenu>
                    <Tab href={"/i/pm-view/overdue-tasks"}>{t("label.overdue-tasks")}</Tab>
                </TabMenu>
            }
        >
            <Outlet />
        </TabLayout>
    );
}

export const Route = createFileRoute("/_internal/i/_pm-view")({
    component: PmViewLayout,
});
