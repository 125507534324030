import { createFileRoute } from "@tanstack/react-router";
import { useTranslation } from "react-i18next";
import { Subheading } from "src/components/base/heading";
import React, { useEffect } from "react";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "src/components/base/table";
import { displayDeadline } from "src/utils/tasks";
import { Checkbox, CheckboxField } from "src/components/base/checkbox";
import { Field, Label } from "src/components/base/fieldset";
import { Input } from "src/components/base/input";
import { Text } from "src/components/base/text";
import { Api } from "src/api/api";
import { toast } from "react-toastify";
import { InternalTask } from "src/api/generated";
import { sortDates } from "src/utils/sorter";
import USER_CONTEXT from "src/context/user";

/**
 * The properties for {@link OverdueTasks}
 */
export type OverdueTasksProps = {};

/**
 * Manage all checkbox states
 */
type CheckboxStates = {
    /** if tasks of pentest projects are shown */
    showPentest: boolean;
    /** if tasks where you are pm or all tasks are shown */
    showRelevant: boolean;
};

const CHECKBOXES_LS = "internal.pm-view.overdue-tasks.checkboxes";

/**
 * Get initial Checkbox state
 *
 * @returns initial checkbox state
 */
const getCheckboxState = () => {
    const checkboxes = localStorage.getItem(CHECKBOXES_LS);

    if (!checkboxes) {
        return undefined;
    }

    const state: CheckboxStates = JSON.parse(checkboxes);
    return state;
};

/**
 * Tab to view customer tasks that are overdue
 */
export default function OverdueTasks(props: OverdueTasksProps) {
    const { user } = React.useContext(USER_CONTEXT);
    const [t] = useTranslation("pm-view");
    const [tg] = useTranslation();

    const [tasks, setTasks] = React.useState<Array<InternalTask>>([]);

    const state = getCheckboxState();
    const [checkboxes, setCheckboxes] = React.useState<CheckboxStates | undefined>(state);

    const [query, setQuery] = React.useState("");

    /**
     * Update checkbox state in local storage and React
     *
     * @param update new state of checkboxes
     */
    const updateCheckboxState = (update: CheckboxStates) => {
        localStorage.setItem(CHECKBOXES_LS, JSON.stringify(update));
        setCheckboxes(update);
    };

    const filteredTasks =
        query === ""
            ? tasks
            : tasks.filter((t) => t.project.customer.legal_name.toLowerCase().includes(query.toLowerCase()));

    useEffect(() => {
        if (!localStorage.getItem(CHECKBOXES_LS)) {
            updateCheckboxState({
                showPentest: true,
                showRelevant: false,
            });
        }

        Api.internal.tasks.allFiltered({ task_state: "Ready", user_role: "Customer" }).then((res) => {
            res.match(
                (tasks) => setTasks(tasks.list),
                (err) => toast.error(err.message),
            );
        });
    }, []);

    if (!checkboxes) {
        return undefined;
    }

    return tasks.length === 0 ? (
        <Text> {t("description.no-tasks")}</Text>
    ) : (
        <div className={"flex flex-col gap-12"}>
            <div className={"flex flex-col gap-6"}>
                <Text>{t("description.overdue-tasks")}</Text>
                <div className={"flex flex-row items-end gap-6"}>
                    <Field className={"max-w-xs"}>
                        <Label>{tg("label.search")}</Label>
                        <Input
                            placeholder={t("label.customer-name")}
                            value={query}
                            onChange={(e) => setQuery(e.target.value)}
                        />
                    </Field>
                    <CheckboxField>
                        <Checkbox
                            color={"blue"}
                            checked={checkboxes.showPentest}
                            onChange={(e) =>
                                updateCheckboxState({
                                    ...checkboxes,
                                    showPentest: e,
                                })
                            }
                        />
                        <Label>{tg("label.pentest")}</Label>
                    </CheckboxField>
                    {/**
                       Uncomment when phishing is implemented & customer has phishing tasks
                     <CheckboxField>
                        <Checkbox
                            color={"blue"}
                            checked={showPhishing !== "false"}
                            onChange={(e) => {
                                if (e) {
                                    localStorage.setItem("showPhishing", "true");
                                    setShowPhishing("true");
                                } else {
                                    localStorage.setItem("showPhishing", "false");
                                    setPhishing("false");
                                }
                            }}
                        />
                        <Label><Label>{tg("label.phishing")}</Label></Label>
                    </CheckboxField>
                      */}
                    <CheckboxField>
                        <Checkbox
                            color={"blue"}
                            checked={checkboxes.showRelevant}
                            onChange={(e) =>
                                updateCheckboxState({
                                    ...checkboxes,
                                    showRelevant: e,
                                })
                            }
                        />
                        <Label title={t("description.own-customer")}>{t("label.own-customer")}</Label>
                    </CheckboxField>
                </div>
            </div>
            {checkboxes.showPentest ? (
                <div className={"flex flex-col gap-3"}>
                    <Subheading>{tg("label.pentest")}</Subheading>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableHeader>{tg("label.due-until")}</TableHeader>
                                <TableHeader>{tg("label.task")}</TableHeader>
                                <TableHeader>{tg("label.next-appointment")}</TableHeader>
                                <TableHeader>{tg("label.legal-business-name")}</TableHeader>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredTasks
                                .sort((a, b) =>
                                    sortDates(
                                        a.deadline ? new Date(a.deadline) : undefined,
                                        b.deadline ? new Date(b.deadline) : undefined,
                                    ),
                                )
                                .filter((t) => t.project.kind === "Pentest")
                                .filter((t) => {
                                    const day_after_tomorrow = new Date(
                                        (Math.floor(new Date().getTime() / 86400000) + 2) * 1000 * 60 * 60 * 24,
                                    );
                                    return t.deadline && new Date(t.deadline).getTime() <= day_after_tomorrow.getTime();
                                })
                                .filter((t) => {
                                    if (checkboxes.showRelevant) {
                                        return t.project.customer.project_manager.uuid === user.uuid;
                                    } else {
                                        return true;
                                    }
                                })
                                .map((task) =>
                                    task.data.kind === "FillPentestTargetsV0" ? (
                                        <TableRow
                                            key={task.uuid}
                                            href={"/i/pentests/$projectId/general"}
                                            params={{ projectId: task.project.uuid }}
                                        >
                                            <TableCell
                                                title={task.deadline && new Date(task.deadline).toLocaleString()}
                                            >
                                                {task.deadline && displayDeadline(new Date(task.deadline))}
                                            </TableCell>
                                            <TableCell>{tg("tasks.label.fill-pentest-targets")}</TableCell>
                                            <TableCell
                                                title={
                                                    task.project.next_meeting_date &&
                                                    new Date(task.project.next_meeting_date).toLocaleString()
                                                }
                                            >
                                                {task.project.next_meeting} : &nbsp;
                                                {task.project.next_meeting_date
                                                    ? displayDeadline(new Date(task.project.next_meeting_date))
                                                    : t("label.not-scheduled")}
                                            </TableCell>
                                            <TableCell>{task.project.customer.legal_name}</TableCell>
                                        </TableRow>
                                    ) : task.data.kind === "FillQuestionnaireV0" ? (
                                        <TableRow
                                            key={task.uuid}
                                            href={"/i/pentests/$projectId/general"}
                                            params={{ projectId: task.project.uuid }}
                                        >
                                            <TableCell
                                                title={task.deadline && new Date(task.deadline).toLocaleString()}
                                            >
                                                {task.deadline && displayDeadline(new Date(task.deadline))}
                                            </TableCell>
                                            <TableCell>
                                                {task.data.usage === "AssessmentOrganizational"
                                                    ? tg("tasks.label.fill-questionnaire-organizational")
                                                    : tg("tasks.label.fill-questionnaire-tech")}
                                            </TableCell>
                                            <TableCell
                                                title={
                                                    task.project.next_meeting_date &&
                                                    new Date(task.project.next_meeting_date).toLocaleString()
                                                }
                                            >
                                                {task.project.next_meeting} : &nbsp;
                                                {task.project.next_meeting_date
                                                    ? displayDeadline(new Date(task.project.next_meeting_date))
                                                    : t("label.not-scheduled")}
                                            </TableCell>
                                            <TableCell>{task.project.customer.legal_name}</TableCell>
                                        </TableRow>
                                    ) : task.data.kind === "AssignProjectContactsV0" ? (
                                        <TableRow
                                            key={task.uuid}
                                            href={"/i/pentests/$projectId/general"}
                                            params={{ projectId: task.project.uuid }}
                                        >
                                            <TableCell
                                                title={task.deadline && new Date(task.deadline).toLocaleString()}
                                            >
                                                {task.deadline && displayDeadline(new Date(task.deadline))}
                                            </TableCell>
                                            <TableCell>{tg("tasks.label.assign-project-contacts")}</TableCell>
                                            <TableCell
                                                title={
                                                    task.project.next_meeting_date &&
                                                    new Date(task.project.next_meeting_date).toLocaleString()
                                                }
                                            >
                                                {task.project.next_meeting} : &nbsp;
                                                {task.project.next_meeting_date
                                                    ? displayDeadline(new Date(task.project.next_meeting_date))
                                                    : t("label.not-scheduled")}
                                            </TableCell>
                                            <TableCell>{task.project.customer.legal_name}</TableCell>
                                        </TableRow>
                                    ) : (
                                        <TableRow
                                            key={task.uuid}
                                            href={"/i/pentests/$projectId/general"}
                                            params={{ projectId: task.project.uuid }}
                                        >
                                            <TableCell
                                                title={task.deadline && new Date(task.deadline).toLocaleString()}
                                            >
                                                {task.deadline && displayDeadline(new Date(task.deadline))}
                                            </TableCell>
                                            <TableCell>{JSON.stringify(task.data)}</TableCell>
                                            <TableCell
                                                title={
                                                    task.project.next_meeting_date &&
                                                    new Date(task.project.next_meeting_date).toLocaleString()
                                                }
                                            >
                                                {task.project.next_meeting} : &nbsp;
                                                {task.project.next_meeting_date
                                                    ? displayDeadline(new Date(task.project.next_meeting_date))
                                                    : t("label.not-scheduled")}
                                            </TableCell>
                                            <TableCell>{task.project.customer.legal_name}</TableCell>
                                        </TableRow>
                                    ),
                                )}
                        </TableBody>
                    </Table>
                </div>
            ) : undefined}
            {/**
             Uncomment when phishing is implemented & customer has phishing tasks
             {phishing ? (
                <div className={"flex flex-col gap-3"}>
                    <Subheading>{tg("label.phishing")}</Subheading>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableHeader>{tg("label.due-until")}</TableHeader>
                                <TableHeader>Aufgabe</TableHeader>
                                <TableHeader>Nächster Termin</TableHeader>
                                <TableHeader>{tg("label.legal-business-name")}</TableHeader>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>{displayDeadline(new Date("12.01.2024"))}</TableCell>
                                <TableCell>Trage Ips ein</TableCell>
                                <TableCell>Dialog: 19.01.2025</TableCell>
                                <TableCell>Weihnachtsmann & kokg</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </div>
            ) : undefined}
              */}
        </div>
    );
}

export const Route = createFileRoute("/_internal/i/_pm-view/pm-view/overdue-tasks")({
    component: OverdueTasks,
});
