import { Dialog, DialogProps, DialogTitle } from "src/components/base/dialog";
import React from "react";
import { Text } from "src/components/base/text";
import { Button } from "src/components/base/button";
import { ArrowPathIcon, ExclamationCircleIcon } from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";

/**
 * The properties for {@link DialogMissingDevice}
 */
export type DialogMissingDeviceProps = DialogProps & {
    /** Callback function when the Dialog is closed */
    onClose: () => void;
    /** Callback function to retry the Api call */
    onRetry: () => void;
};

/**
 * Dialog to open when the error MissingDevice occurs which shows text for what to do next
 */
export default function DialogMissingDevice(props: DialogMissingDeviceProps) {
    const [t] = useTranslation("dialog-missing-device");
    const { onClose, onRetry } = props;

    return (
        <Dialog {...props}>
            <DialogTitle className={"mb-3 flex items-center gap-3"}>
                <ExclamationCircleIcon className={"h-5"} />
                {t("heading.title")}
            </DialogTitle>
            <Text>
                {t("description.1")}
                <ol className={"mx-6 mt-3 list-decimal"}>
                    <li>{t("description.2")}</li>
                    <li className={"mt-3"}>{t("description.3")}</li>
                </ol>
            </Text>
            <div className={"flex justify-between"}>
                <Button plain={true} className={"mt-6"} onClick={() => onClose()}>
                    {t("button.close")}
                </Button>
                <Button onClick={() => onRetry()} color={"blue"} className={"mt-5"}>
                    <ArrowPathIcon />
                    {t("button.retry")}
                </Button>
            </div>
        </Dialog>
    );
}
