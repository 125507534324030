import React from "react";
import { clsx } from "clsx";
import { FullQuestion, FullQuestionnaireQuestion, SubmitCurrentAnswerRequest } from "../../api/generated";
import { Heading } from "../base/heading";
import { Text } from "../base/text";
import { Button } from "../base/button";
import { useTranslation } from "react-i18next";
import { Radio, RadioField, RadioGroup } from "../base/radio";
import { Description, ErrorMessage, Field, Label } from "../base/fieldset";
import { Checkbox, CheckboxField, CheckboxGroup } from "../base/checkbox";
import { Input } from "../base/input";
import { UUID } from "../../api/api";

/**
 * The properties for {@link Question}
 */
export type QuestionProps = {
    /** The question that should be rendered */
    question: FullQuestion | FullQuestionnaireQuestion;
    /** Whether the question should be disabled */
    disabled?: boolean;
    /** The action that should be called when the question is submitted */
    onNext?: (req: SubmitCurrentAnswerRequest) => void;
};

/**
 * The common question
 */
export default function Question(props: QuestionProps) {
    const [t] = useTranslation();

    const { question, disabled } = props;

    const [bool, setBool] = React.useState<"yes" | "no">("yes");
    const [number, setNumber] = React.useState(0);
    const [singleChoice, setSingleChoice] = React.useState("");
    const [singleChoiceError, setSingleChoiceError] = React.useState("");
    const [multipleChoices, setMultipleChoices] = React.useState<Array<UUID>>([]);

    /** Helper function to submit the current question */
    const callNext = () => {
        if (question.data.kind === "SingleChoice" && singleChoice === "") {
            setSingleChoiceError(t("error.select-one"));
            return;
        }

        const submission: SubmitCurrentAnswerRequest =
            question.data.kind === "Info"
                ? { kind: "Info" }
                : question.data.kind === "Boolean"
                  ? { kind: "Boolean", value: bool === "yes" }
                  : question.data.kind === "Number"
                    ? { kind: "Number", value: number }
                    : question.data.kind === "SingleChoice"
                      ? { kind: "SingleChoice", value: singleChoice }
                      : { kind: "MultipleChoices", value: multipleChoices };

        props.onNext && props.onNext(submission);
    };

    return (
        <div
            className={clsx(
                // Base
                "flex flex-col gap-6 rounded-lg border border-zinc-200 bg-zinc-100 p-12 shadow-lg",
                // Dark
                "dark:border-zinc-700 dark:bg-zinc-800",
            )}
        >
            <div
                className={clsx(
                    "flex w-full flex-col gap-2 border-b pb-3",
                    // Colors
                    "dark:border-white/10",
                )}
            >
                <Heading>{question.title}</Heading>
                <Text>
                    {question.description.split("\n").map((item, i) => (
                        <p key={i}>{item}</p>
                    ))}
                </Text>
            </div>

            {question.data.kind === "Info" && <Text>{question.data.text}</Text>}

            {question.data.kind === "Boolean" && (
                <RadioGroup value={bool} onChange={(v: "yes" | "no") => setBool(v)} disabled={disabled}>
                    <RadioField>
                        <Radio color={"blue"} value={"yes"} />
                        <Label>{t("label.yes")}</Label>
                    </RadioField>
                    <RadioField>
                        <Radio color={"blue"} value={"no"} />
                        <Label>{t("label.no")}</Label>
                    </RadioField>
                </RadioGroup>
            )}

            {question.data.kind === "Number" && (
                <Field className={"max-w-60"}>
                    <Input
                        disabled={disabled}
                        type={"number"}
                        required={true}
                        value={number}
                        onChange={(e) => setNumber(parseInt(e.target.value))}
                    />
                </Field>
            )}

            {question.data.kind === "SingleChoice" && (
                <RadioGroup value={singleChoice} onChange={(v) => setSingleChoice(v)} disabled={disabled}>
                    {question.data.choices.map((choice) => (
                        <RadioField key={choice.uuid}>
                            <Radio color={"blue"} value={choice.uuid} />
                            <Label>{choice.title}</Label>
                            {choice.description && <Description>{choice.description}</Description>}
                        </RadioField>
                    ))}
                    {singleChoiceError !== "" && <ErrorMessage>{singleChoiceError}</ErrorMessage>}
                </RadioGroup>
            )}

            {question.data.kind === "MultipleChoices" && (
                <CheckboxGroup>
                    {question.data.choices.map((choice) => (
                        <CheckboxField key={choice.uuid} disabled={disabled}>
                            <Checkbox
                                color={"blue"}
                                checked={multipleChoices.includes(choice.uuid)}
                                onChange={(checked) => {
                                    setMultipleChoices((prev) => {
                                        let choices = [...prev];
                                        if (checked) {
                                            choices.push(choice.uuid);
                                        } else {
                                            choices = [...choices.filter((v) => v !== choice.uuid)];
                                        }

                                        return choices;
                                    });
                                }}
                            />
                            <Label>{choice.title}</Label>
                            {choice.description && <Description>{choice.description}</Description>}
                        </CheckboxField>
                    ))}
                </CheckboxGroup>
            )}

            <Button className={"place-self-end"} color={"blue"} disabled={disabled} onClick={callNext}>
                {t("button.submit-question")}
            </Button>
        </div>
    );
}
