import { createFileRoute } from "@tanstack/react-router";

import React from "react";
import CUSTOMER_TASKS_CONTEXT from "src/context/tasks-customer";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "src/components/base/table";
import { useTranslation } from "react-i18next";
import HeadingLayout from "src/components/base/heading-layout";
import { sortDates } from "src/utils/sorter";
import { displayDeadline } from "src/utils/tasks";
import { Text } from "src/components/base/text";

/**
 * The properties for {@link CustomerTasks}
 */
export type CustomerTasksProps = {};

/**
 * The tasks of a customer
 */
export default function CustomerTasks(props: CustomerTasksProps) {
    const [tg] = useTranslation();
    const [t] = useTranslation("customer");
    const { tasks } = React.useContext(CUSTOMER_TASKS_CONTEXT);

    return (
        <HeadingLayout heading={t("tasks.heading")}>
            {tasks.length === 0 ? (
                <Text>{t("pentest-project.description.no-tasks-left")}</Text>
            ) : (
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableHeader>{tg("label.due-until")}</TableHeader>
                            <TableHeader>{tg("label.task")}</TableHeader>
                            <TableHeader>{tg("label.project")}</TableHeader>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tasks
                            .sort((a, b) =>
                                sortDates(
                                    a.deadline !== undefined ? new Date(a.deadline) : undefined,
                                    b.deadline !== undefined ? new Date(b.deadline) : undefined,
                                ),
                            )
                            .map((task) =>
                                task.data.kind === "FillPentestTargetsV0" ? (
                                    <TableRow
                                        key={task.uuid}
                                        href={"/c/forms/tasks/$taskId/target"}
                                        params={{
                                            taskId: task.uuid,
                                        }}
                                    >
                                        <TableCell title={task.deadline && new Date(task.deadline).toLocaleString()}>
                                            {task.deadline && displayDeadline(new Date(task.deadline))}
                                        </TableCell>
                                        <TableCell>{tg("tasks.label.fill-pentest-targets")}</TableCell>
                                        <TableCell>{task.project?.kind}</TableCell>
                                    </TableRow>
                                ) : task.data.kind === "FillQuestionnaireV0" ? (
                                    <TableRow
                                        key={task.uuid}
                                        href={"/c/forms/tasks/$taskId/questionnaire"}
                                        params={{
                                            taskId: task.uuid,
                                        }}
                                    >
                                        <TableCell title={task.deadline && new Date(task.deadline).toLocaleString()}>
                                            {task.deadline && displayDeadline(new Date(task.deadline))}
                                        </TableCell>
                                        <TableCell>
                                            {task.data.usage === "AssessmentOrganizational"
                                                ? tg("tasks.label.fill-questionnaire-organizational")
                                                : tg("tasks.label.fill-questionnaire-tech")}
                                        </TableCell>
                                        <TableCell>{task.project?.kind}</TableCell>
                                    </TableRow>
                                ) : task.data.kind === "AssignProjectContactsV0" ? (
                                    <TableRow
                                        key={task.uuid}
                                        href={"/c/forms/tasks/$taskId/assign-project-contacts"}
                                        params={{ taskId: task.uuid }}
                                    >
                                        <TableCell title={task.deadline && new Date(task.deadline).toLocaleString()}>
                                            {task.deadline && displayDeadline(new Date(task.deadline))}
                                        </TableCell>
                                        <TableCell>{tg("tasks.label.assign-project-contacts")}</TableCell>
                                        <TableCell>{task.project?.kind}</TableCell>
                                    </TableRow>
                                ) : (
                                    <TableRow key={task.uuid}>
                                        <TableCell title={task.deadline && new Date(task.deadline).toLocaleString()}>
                                            {task.deadline && displayDeadline(new Date(task.deadline))}
                                        </TableCell>
                                        <TableCell>{JSON.stringify(task.data)}</TableCell>
                                        <TableCell>{task.project?.kind}</TableCell>
                                    </TableRow>
                                ),
                            )}
                    </TableBody>
                </Table>
            )}
        </HeadingLayout>
    );
}

export const Route = createFileRoute("/_customer/c/tasks")({
    component: CustomerTasks,
});
