import { createFileRoute } from "@tanstack/react-router";

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "@tanstack/react-form";
import { Api } from "src/api/api";
import { toast } from "react-toastify";
import Form from "src/components/base/form";
import { Field, FieldGroup, Fieldset, Label } from "src/components/base/fieldset";
import { Button } from "src/components/base/button";
import { Textarea } from "src/components/base/textarea";
import BlockUnsavedChanges from "src/components/base/unsaved-changes";
import HeadingLayout from "src/components/base/heading-layout";
import { Divider } from "src/components/base/divider";
import UpsellOptions from "src/components/internal/upsell-options";
import { CreateOfferPotentialRequest } from "src/api/generated";
import { SimpleCustomerFile } from "src/api/generated";

/**
 * The properties for {@link EnterManagementPresentationResultsForm}
 */
export type EnterManagementPresentationResultsFormProps = {};

/**
 * The form to enter dialog results
 */
function EnterManagementPresentationResultsForm(props: EnterManagementPresentationResultsFormProps) {
    const [t] = useTranslation();
    const [presentation, setPresentation] = React.useState<SimpleCustomerFile | undefined>(undefined);
    const [report, setReport] = React.useState<SimpleCustomerFile | undefined>(undefined);
    const { taskId } = Route.useParams();

    const form = useForm({
        defaultValues: {
            feedbackDate: "",
            skipFeedbackDate: false,
            // eslint-disable-next-line
            selectedOfferSuggestions: [] as Array<CreateOfferPotentialRequest & { name: string; label?: string }>,
            notes: "",
        },
        // eslint-disable-next-line
        onSubmit: async ({ formApi, value }) => {
            const res = await Api.internal.projects.pentest.setManagementPresentationResultsV1(taskId, {
                feedback_date: undefined,
                offer_potentials: value.selectedOfferSuggestions.map((x) => ({ data: x.data, topic: x.topic })),
                notes: value.notes,
            });
            res.match(
                (res) => {
                    if (res.result === "Ok") {
                        window.history.back();
                    } else {
                        if (res.error.feedback_date) {
                            formApi.setFieldMeta("feedbackDate", (meta) => {
                                meta.errors.push(t("error.date-in-past"));
                                return meta;
                            });
                        }
                    }
                },
                (err) => toast.error(err.message),
            );
        },
    });
    const isDirty = form.useStore((store) => store.isDirty);
    const _skipFeedback = form.useStore((store) => store.values.skipFeedbackDate);
    const [customerName, setCustomerName] = React.useState<string>("");

    useEffect(() => {
        Api.internal.projects.pentest.getEnterResults(taskId).then((res) => {
            res.match(
                (res) => {
                    setReport(res.report);
                    setPresentation(res.mgmt_presentation_presentation);
                },
                (err) => toast.error(err.message),
            );
        });
        Api.internal.tasks.getReady(taskId).then((res) => {
            res.match(
                (res) => {
                    if (res.project) {
                        setCustomerName(res.project.customer.legal_name);
                    }
                },
                (err) => toast.error(err.message),
            );
        });
    }, []);

    return (
        <HeadingLayout
            heading={t("internal.enter-mgmt-presentation-results.heading")}
            headingDescription={t("label.customer") + ": " + customerName}
            headingChildren={
                <div className={"flex flex-row items-center justify-between gap-6"}>
                    {presentation && (
                        <Button
                            target={"_blank"}
                            href={`/api/frontend/v1/internal/customers/files/${presentation.uuid}`}
                            download={presentation.name}
                        >
                            {t("button.download-pres")}
                        </Button>
                    )}
                    {report && (
                        <Button
                            target={"_blank"}
                            href={`/api/frontend/v1/internal/customers/files/${report.uuid}`}
                            download={report.name}
                        >
                            {t("button.download-report")}
                        </Button>
                    )}
                </div>
            }
        >
            <Form onSubmit={form.handleSubmit} className={"max-w-lg"}>
                <Fieldset>
                    <FieldGroup>
                        {/*

                        Commented out as 

                        <form.Field name={"feedbackDate"}>
                            {(fieldApi) => (
                                <Field>
                                    <RequiredLabel>{t("label.feedback-date")}</RequiredLabel>
                                    <Input
                                        disabled={skipFeedback}
                                        required={true}
                                        autoFocus={true}
                                        type={"datetime-local"}
                                        value={fieldApi.state.value}
                                        invalid={fieldApi.state.meta.errors.length > 0}
                                        onChange={(e) => fieldApi.handleChange(e.target.value)}
                                    />
                                    {fieldApi.state.meta.errors.map((err) => (
                                        <ErrorMessage>{err}</ErrorMessage>
                                    ))}
                                </Field>
                            )}
                        </form.Field>

                        <form.Field name={"skipFeedbackDate"}>
                            {(fieldApi) => (
                                <Field>
                                    <Label>{t("label.skip-feedback")}</Label>
                                    <CheckboxGroup>
                                        <Checkbox checked={fieldApi.state.value} onChange={fieldApi.handleChange} />
                                    </CheckboxGroup>
                                </Field>
                            )}
                        </form.Field>*/}

                        <form.Field name={"selectedOfferSuggestions"}>
                            {(fieldApi) => (
                                <UpsellOptions
                                    selectedOfferSuggestions={fieldApi.state.value}
                                    handleChange={(list) => {
                                        fieldApi.handleChange(list);
                                    }}
                                />
                            )}
                        </form.Field>

                        <Divider />

                        <form.Field name={"notes"}>
                            {(fieldApi) => (
                                <Field>
                                    <Label>{t("label.notes")}</Label>
                                    <Textarea
                                        value={fieldApi.state.value}
                                        onChange={(e) => fieldApi.handleChange(e.target.value)}
                                    />
                                </Field>
                            )}
                        </form.Field>

                        <Button type={"submit"} color={"blue"}>
                            {t("button.submit")}
                        </Button>
                    </FieldGroup>
                </Fieldset>
            </Form>

            <BlockUnsavedChanges condition={isDirty} />
        </HeadingLayout>
    );
}

export const Route = createFileRoute("/_internal/i/forms/tasks/$taskId/enter-mgmt-results-v1")({
    component: EnterManagementPresentationResultsForm,
});
