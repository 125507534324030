import React from "react";
import { Dialog, DialogActions, DialogBody, DialogProps, DialogTitle } from "src/components/base/dialog";
import { useForm } from "@tanstack/react-form";
import Form from "src/components/base/form";
import { Button } from "src/components/base/button";
import { useTranslation } from "react-i18next";
import { Api, UUID } from "src/api/api";
import { toast } from "react-toastify";
import { Description, ErrorMessage, Field, FieldGroup, Fieldset, RequiredLabel } from "src/components/base/fieldset";
import { SimpleOfferPotentialTopicData } from "src/api/generated";
import { Input } from "src/components/base/input";
import { Listbox, ListboxLabel, ListboxOption } from "src/components/base/listbox";

/**
 * The properties for {@link DialogCreateOfferSuggestion}
 */
export type DialogCreateOfferSuggestionProps = DialogProps & {
    /** On create callback */
    onCreate: (uuid: UUID) => Promise<void>;
};

/**
 * A dialog to create an offer suggestion
 */
export default function DialogCreateOfferSuggestion(props: DialogCreateOfferSuggestionProps) {
    const [t] = useTranslation("internal-create-offer-suggestion");
    const [tg] = useTranslation();

    const form = useForm({
        defaultValues: {
            name: "",
            type: "None" as SimpleOfferPotentialTopicData["type"],
            label: "",
        },
        // eslint-disable-next-line
        onSubmit: async ({ value, formApi }) => {
            const res = await Api.internal.offerPotentialTopics.create({
                name: value.name,
                data:
                    value.type === "None"
                        ? { type: value.type }
                        : value.type === "Size"
                          ? { type: value.type, label: value.label }
                          : { type: value.type, label: value.label },
            });
            if (res.isErr) {
                toast.error(res.err.message);
                return;
            }

            if (res.ok.result === "Ok") {
                await props.onCreate(res.ok.value.uuid);
            } else {
                if (res.ok.error.name) {
                    formApi.setFieldMeta("name", (meta) => {
                        meta.errors.push(t("error.name-already-in-use"));
                        return meta;
                    });
                }
            }
        },
    });

    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <Form onSubmit={form.handleSubmit}>
                <DialogTitle>{t("heading.create-offer-suggestion")}</DialogTitle>
                <DialogBody>
                    <Fieldset>
                        <FieldGroup>
                            <form.Field name={"name"}>
                                {(fieldApi) => (
                                    <Field>
                                        <RequiredLabel>{t("label.name")}</RequiredLabel>
                                        <Input
                                            required={true}
                                            value={fieldApi.state.value}
                                            onChange={(e) => fieldApi.handleChange(e.target.value)}
                                            invalid={fieldApi.state.meta.errors.length > 0}
                                            autoFocus={true}
                                        />
                                        {fieldApi.state.meta.errors.map((err) => (
                                            <ErrorMessage>{err}</ErrorMessage>
                                        ))}
                                    </Field>
                                )}
                            </form.Field>

                            <form.Field name={"type"}>
                                {(fieldApi) => (
                                    <Field>
                                        <RequiredLabel>{t("label.type")}</RequiredLabel>
                                        <Listbox
                                            value={fieldApi.state.value}
                                            onChange={(e) => fieldApi.handleChange(e)}
                                        >
                                            <ListboxOption value={"None"}>
                                                <ListboxLabel>{t("label.type-none")}</ListboxLabel>
                                            </ListboxOption>
                                            <ListboxOption value={"Size"}>
                                                <ListboxLabel>{t("label.type-size")}</ListboxLabel>
                                            </ListboxOption>
                                            <ListboxOption value={"Custom"}>
                                                <ListboxLabel>{t("label.type-custom")}</ListboxLabel>
                                            </ListboxOption>
                                        </Listbox>
                                    </Field>
                                )}
                            </form.Field>

                            <form.Subscribe selector={(state) => state.values.type}>
                                {(type) =>
                                    type === "Size" || type === "Custom" ? (
                                        <form.Field name={"label"}>
                                            {(fieldApi) => (
                                                <Field>
                                                    <RequiredLabel>{t("label.label")}</RequiredLabel>
                                                    <Description>{t("description.label")}</Description>
                                                    <Input
                                                        required={true}
                                                        value={fieldApi.state.value}
                                                        onChange={(e) => fieldApi.handleChange(e.target.value)}
                                                    />
                                                </Field>
                                            )}
                                        </form.Field>
                                    ) : undefined
                                }
                            </form.Subscribe>
                        </FieldGroup>
                    </Fieldset>
                </DialogBody>
                <DialogActions>
                    <Button type={"submit"}>{tg("button.create")}</Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}
