import { Api } from "src/api/api";
import { useTranslation } from "react-i18next";
import { useForm } from "@tanstack/react-form";
import { validateDate } from "src/utils/validators";
import { toast } from "react-toastify";
import Form from "src/components/base/form";
import { Description, ErrorMessage, Field, FieldGroup, Fieldset, Label, Legend } from "src/components/base/fieldset";
import { Input } from "src/components/base/input";
import { Button } from "src/components/base/button";
import React from "react";
import PENTEST_PROJECT_CONTEXT from "src/context/pentest-project-internal";
import { FullPentestProject } from "src/api/generated";
import { ChevronLeftIcon } from "@heroicons/react/20/solid";
import { Text } from "src/components/base/text";

/**
 * The properties for {@link ChangeDate}
 */
export type DialogChangeDateProps = {
    /** Type of the appointment*/
    type: AppointmentType;
};

/** Type of the appointment*/
type AppointmentType = "kickoff" | "dialog" | "mgmt" | "debrief";

/**
 * Change Date of an appointment
 */
export default function ChangeDate(props: DialogChangeDateProps) {
    const { type } = props;

    const [t] = useTranslation();

    const { obj, reset } = React.useContext(PENTEST_PROJECT_CONTEXT);
    const project = obj as FullPentestProject;

    const changeForm = useForm({
        defaultValues: {
            date: "",
        },
        // eslint-disable-next-line
        onSubmit: async ({ value, formApi }) => {
            const d = validateDate(value.date);
            if (d.isErr) {
                formApi.setFieldMeta("date", (meta) => {
                    meta.errors.push(t("error.invalid-date"));
                    return meta;
                });
                return;
            }
            let res;
            switch (type) {
                case "kickoff": {
                    res = await Api.internal.projects.pentest.changeKickoffDate(project.uuid, d.ok.toISOString());
                    break;
                }
                case "dialog": {
                    res = await Api.internal.projects.pentest.changeDialogDate(project.uuid, d.ok.toISOString());
                    break;
                }
                case "mgmt": {
                    res = await Api.internal.projects.pentest.changePresentationDate(project.uuid, d.ok.toISOString());
                    break;
                }
                case "debrief": {
                    res = await Api.internal.projects.pentest.changeDebriefDate(project.uuid, d.ok.toISOString());
                    break;
                }
                default:
                    break;
            }
            if (res !== undefined) {
                res.match(
                    () => {
                        reset();
                        history.back();
                    },
                    (err) => toast.error(err.message),
                );
            }
        },
    });

    return (
        <Form onSubmit={changeForm.handleSubmit} className={"max-w-lg"}>
            <Fieldset>
                <Button plain={true} onClick={() => window.history.back()} params={{ projectId: obj.uuid }}>
                    <ChevronLeftIcon /> <Text>{t("button.back")}</Text>
                </Button>

                <FieldGroup>
                    <changeForm.Field name={"date"}>
                        {(fieldApi) => (
                            <Field>
                                <Legend className={"mb-5"}>
                                    {t("internal.projects.heading.change-date", {
                                        name:
                                            type === "kickoff"
                                                ? t("label.kickoff")
                                                : type === "dialog"
                                                  ? t("label.dialog")
                                                  : type === "mgmt"
                                                    ? t("label.mgmt-presentation")
                                                    : t("label.debrief"),
                                    })}
                                    :
                                </Legend>
                                <Label>{t("label.new-date")}</Label>

                                <Description>
                                    {t("description.current-date", {
                                        date:
                                            type === "kickoff"
                                                ? project.kick_off?.state && project.kick_off.state === "Scheduled"
                                                    ? new Date(project.kick_off.date).toLocaleString()
                                                    : t("label.not-scheduled-yet")
                                                : type === "dialog"
                                                  ? project.dialog?.state && project.dialog.state === "Scheduled"
                                                      ? new Date(project.dialog.date).toLocaleString()
                                                      : t("label.not-scheduled-yet")
                                                  : type === "mgmt"
                                                    ? project.mgmt_presentation?.state &&
                                                      project.mgmt_presentation.state === "Scheduled"
                                                        ? new Date(project.mgmt_presentation.date).toLocaleString()
                                                        : t("label.not-scheduled-yet")
                                                    : type === "debrief"
                                                      ? project.debrief?.state && project.debrief.state === "Scheduled"
                                                          ? new Date(project.debrief.date).toLocaleString()
                                                          : t("label.not-scheduled-yet")
                                                      : t("label.not-scheduled-yet"),
                                    })}
                                </Description>

                                <Input
                                    type={"datetime-local"}
                                    autoFocus={true}
                                    invalid={fieldApi.state.meta.errors.length > 0}
                                    value={fieldApi.state.value}
                                    onChange={(e) => fieldApi.handleChange(e.target.value)}
                                />
                                {fieldApi.state.meta.errors.map((e) => (
                                    <ErrorMessage>{e}</ErrorMessage>
                                ))}
                            </Field>
                        )}
                    </changeForm.Field>
                    <Button type={"submit"} color={"blue"}>
                        {t("button.change")}
                    </Button>
                </FieldGroup>
            </Fieldset>
        </Form>
    );
}
