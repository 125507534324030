import { Dialog, DialogActions, DialogBody, DialogProps, DialogTitle } from "../../base/dialog";
import { useTranslation } from "react-i18next";
import { Api } from "../../../api/api";
import React, { useState } from "react";
import { toast } from "react-toastify";
import Form from "../../base/form";
import { Field, FieldGroup, Fieldset, Label, RequiredLabel } from "../../base/fieldset";
import { Input } from "../../base/input";
import { Button } from "../../base/button";

/**
 * The properties for {@link DialogCreateCustomerContact}
 */
export type DialogCreateCustomerContactProps = DialogProps & {
    /** The action that should be called if the form was submitted successfully */
    onSubmit: (contactUuid: string) => void;
};

/**
 * The dialogs to add a new contact for a customer
 */
export default function DialogCreateCustomerContact(props: DialogCreateCustomerContactProps) {
    const { onSubmit, ...dialogProps } = props;

    const [t] = useTranslation("customer");
    const [tg] = useTranslation();

    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");

    /**
     * The api call for the customer to create a contact for the project
     */
    const createContact = () => {
        Api.customer.contacts
            .create({
                email,
                name,
                phone,
            })
            .then((result) =>
                result.match(
                    ({ uuid }) => onSubmit(uuid),
                    (err) => {
                        toast.error(err.message);
                    },
                ),
            );
    };

    return (
        <Dialog open={dialogProps.open} onClose={dialogProps.onClose}>
            <Form onSubmit={() => createContact()}>
                <DialogTitle>{t("assign-project-contacts.create-contact.heading")}</DialogTitle>
                <DialogBody>
                    <Fieldset>
                        <FieldGroup>
                            <Field>
                                <RequiredLabel>{tg("label.name")}</RequiredLabel>
                                <Input
                                    autoFocus={true}
                                    type={"text"}
                                    value={name}
                                    required={true}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </Field>

                            <Field>
                                <RequiredLabel>{tg("label.mail")}</RequiredLabel>
                                <Input
                                    type={"email"}
                                    value={email}
                                    required={true}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </Field>

                            <Field>
                                <Label>{tg("label.phone")}</Label>
                                <Input type={"tel"} value={phone} onChange={(e) => setPhone(e.target.value)} />
                            </Field>
                        </FieldGroup>
                    </Fieldset>
                </DialogBody>
                <DialogActions>
                    <Button type={"submit"} color={"blue"}>
                        {t("assign-project-contacts.create-contact.button")}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}
