/* tslint:disable */
/* eslint-disable */
/**
 * Unnamed API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiErrorResponse,
  CreateTotpRequest,
  CreateUserResetRequest,
  CreateWebauthnRequest,
  FormResultForNullAndCreateTotpErrors,
  FormResultForNullAndDeletePasswordErrors,
  FormResultForNullAndDeleteWebauthnKeyErrors,
  FormResultForNullAndSetPasswordErrors,
  FormResultForNullAndSubmitResetErrors,
  FormResultForSimpleUserResetAndCreateUserResetErrors,
  FormResultForWebAuthnSchemaAndCreateWebauthnErrors,
  PrepareUserResetResponse,
  SetPasswordRequest,
  WebAuthnRegisterResult,
} from '../models/index';

export interface CompleteResetWebauthnRequest {
    body: any | null;
}

export interface CreateTotpKeyResetRequest {
    CreateTotpRequest: CreateTotpRequest;
}

export interface CreateUserResetOperationRequest {
    CreateUserResetRequest: CreateUserResetRequest;
}

export interface CreateWebauthnKeyResetRequest {
    CreateWebauthnRequest: CreateWebauthnRequest;
}

export interface DeleteTotpKeyResetRequest {
    uuid: string;
}

export interface DeleteWebauthnKeyResetRequest {
    uuid: string;
}

export interface PrepareUserResetRequest {
    uuid: string;
}

export interface SetPasswordResetRequest {
    SetPasswordRequest: SetPasswordRequest;
}

/**
 * 
 */
export class UserResetsApi extends runtime.BaseAPI {

    /**
     * Complete the webauthn challenge for adding a new key  This may only be called between begin and submit.
     * Complete the webauthn challenge for adding a new key
     */
    async completeResetWebauthnRaw(requestParameters: CompleteResetWebauthnRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WebAuthnRegisterResult>> {
        if (requestParameters['body'] == null) {
            throw new runtime.RequiredError(
                'body',
                'Required parameter "body" was null or undefined when calling completeResetWebauthn().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/common/user-resets/complete-webauthn`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Complete the webauthn challenge for adding a new key  This may only be called between begin and submit.
     * Complete the webauthn challenge for adding a new key
     */
    async completeResetWebauthn(requestParameters: CompleteResetWebauthnRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WebAuthnRegisterResult> {
        const response = await this.completeResetWebauthnRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Adds a TOTP key  This may only be called between begin and submit.
     * Adds a TOTP key
     */
    async createTotpKeyResetRaw(requestParameters: CreateTotpKeyResetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormResultForNullAndCreateTotpErrors>> {
        if (requestParameters['CreateTotpRequest'] == null) {
            throw new runtime.RequiredError(
                'CreateTotpRequest',
                'Required parameter "CreateTotpRequest" was null or undefined when calling createTotpKeyReset().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/common/user-resets/totp`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['CreateTotpRequest'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Adds a TOTP key  This may only be called between begin and submit.
     * Adds a TOTP key
     */
    async createTotpKeyReset(requestParameters: CreateTotpKeyResetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormResultForNullAndCreateTotpErrors> {
        const response = await this.createTotpKeyResetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates a new reset link for a user\'s authentication methods and resets all authenticated sessions.
     * Creates a new reset link for a user\'s authentication methods and resets all authenticated sessions.
     */
    async createUserResetRaw(requestParameters: CreateUserResetOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormResultForSimpleUserResetAndCreateUserResetErrors>> {
        if (requestParameters['CreateUserResetRequest'] == null) {
            throw new runtime.RequiredError(
                'CreateUserResetRequest',
                'Required parameter "CreateUserResetRequest" was null or undefined when calling createUserReset().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/admin/user-resets`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['CreateUserResetRequest'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Creates a new reset link for a user\'s authentication methods and resets all authenticated sessions.
     * Creates a new reset link for a user\'s authentication methods and resets all authenticated sessions.
     */
    async createUserReset(requestParameters: CreateUserResetOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormResultForSimpleUserResetAndCreateUserResetErrors> {
        const response = await this.createUserResetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Adds a webauthn key  This may only be called between begin and submit.
     * Adds a webauthn key
     */
    async createWebauthnKeyResetRaw(requestParameters: CreateWebauthnKeyResetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormResultForWebAuthnSchemaAndCreateWebauthnErrors>> {
        if (requestParameters['CreateWebauthnRequest'] == null) {
            throw new runtime.RequiredError(
                'CreateWebauthnRequest',
                'Required parameter "CreateWebauthnRequest" was null or undefined when calling createWebauthnKeyReset().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/common/user-resets/webauthn`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['CreateWebauthnRequest'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Adds a webauthn key  This may only be called between begin and submit.
     * Adds a webauthn key
     */
    async createWebauthnKeyReset(requestParameters: CreateWebauthnKeyResetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormResultForWebAuthnSchemaAndCreateWebauthnErrors> {
        const response = await this.createWebauthnKeyResetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes an existing password (and all MFA keys!)  This may only be called between begin and submit.
     * Deletes an existing password (and all MFA keys!)
     */
    async deletePasswordResetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormResultForNullAndDeletePasswordErrors>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/common/user-resets/password`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Deletes an existing password (and all MFA keys!)  This may only be called between begin and submit.
     * Deletes an existing password (and all MFA keys!)
     */
    async deletePasswordReset(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormResultForNullAndDeletePasswordErrors> {
        const response = await this.deletePasswordResetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Removes a TOTP key  This may only be called between begin and submit.
     * Removes a TOTP key
     */
    async deleteTotpKeyResetRaw(requestParameters: DeleteTotpKeyResetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling deleteTotpKeyReset().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/common/user-resets/totp/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Removes a TOTP key  This may only be called between begin and submit.
     * Removes a TOTP key
     */
    async deleteTotpKeyReset(requestParameters: DeleteTotpKeyResetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteTotpKeyResetRaw(requestParameters, initOverrides);
    }

    /**
     * Removes a webauthn key  This may only be called between begin and submit.
     * Removes a webauthn key
     */
    async deleteWebauthnKeyResetRaw(requestParameters: DeleteWebauthnKeyResetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormResultForNullAndDeleteWebauthnKeyErrors>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling deleteWebauthnKeyReset().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/common/user-resets/webauthn/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Removes a webauthn key  This may only be called between begin and submit.
     * Removes a webauthn key
     */
    async deleteWebauthnKeyReset(requestParameters: DeleteWebauthnKeyResetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormResultForNullAndDeleteWebauthnKeyErrors> {
        const response = await this.deleteWebauthnKeyResetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async prepareUserResetRaw(requestParameters: PrepareUserResetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PrepareUserResetResponse>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling prepareUserReset().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/common/user-resets/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async prepareUserReset(requestParameters: PrepareUserResetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PrepareUserResetResponse> {
        const response = await this.prepareUserResetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Sets a new password (overwriting an existing one)  This may only be called between begin and submit.
     * Sets a new password (overwriting an existing one)
     */
    async setPasswordResetRaw(requestParameters: SetPasswordResetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormResultForNullAndSetPasswordErrors>> {
        if (requestParameters['SetPasswordRequest'] == null) {
            throw new runtime.RequiredError(
                'SetPasswordRequest',
                'Required parameter "SetPasswordRequest" was null or undefined when calling setPasswordReset().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/common/user-resets/password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['SetPasswordRequest'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Sets a new password (overwriting an existing one)  This may only be called between begin and submit.
     * Sets a new password (overwriting an existing one)
     */
    async setPasswordReset(requestParameters: SetPasswordResetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormResultForNullAndSetPasswordErrors> {
        const response = await this.setPasswordResetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Submit the changes to one\'s authentication methods and finish the reset process  This may only be called after begin.
     * Submit the changes to one\'s authentication methods and finish the reset process
     */
    async submitResetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormResultForNullAndSubmitResetErrors>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/common/user-resets/submit`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Submit the changes to one\'s authentication methods and finish the reset process  This may only be called after begin.
     * Submit the changes to one\'s authentication methods and finish the reset process
     */
    async submitReset(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormResultForNullAndSubmitResetErrors> {
        const response = await this.submitResetRaw(initOverrides);
        return await response.value();
    }

}
