import { createFileRoute, Navigate } from "@tanstack/react-router";

/**
 * The properties for the {@link PmView}
 */
export type PmViewProps = {};

/**
 * Redirect to a tab in PM Corner
 */
function PmView(props: PmViewProps) {
    return <Navigate to={"/i/pm-view/overdue-tasks"} />;
}

export const Route = createFileRoute("/_internal/i/_pm-view/pm-view/")({
    component: PmView,
});
