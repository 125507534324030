/* tslint:disable */
/* eslint-disable */
/**
 * Unnamed API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiErrorResponse,
  GetCurrentQuestionResponse,
  OptionalForExportQuestionnaireJsonResponse,
  OptionalForString,
  SubmitCurrentAnswerRequest,
  SubmitCurrentAnswerResponse,
} from '../models/index';

export interface ExportQuestionnaireJsonRequest {
    uuid: string;
}

export interface ExportQuestionnaireTxtRequest {
    uuid: string;
}

export interface GetCurrentQuestionRequest {
    uuid: string;
}

export interface SubmitCurrentAnswerOperationRequest {
    uuid: string;
    SubmitCurrentAnswerRequest: SubmitCurrentAnswerRequest;
}

/**
 * 
 */
export class QuestionnairesApi extends runtime.BaseAPI {

    /**
     */
    async exportQuestionnaireJsonRaw(requestParameters: ExportQuestionnaireJsonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OptionalForExportQuestionnaireJsonResponse>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling exportQuestionnaireJson().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/internal/questionnaire/questionnaire/{uuid}/json`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async exportQuestionnaireJson(requestParameters: ExportQuestionnaireJsonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OptionalForExportQuestionnaireJsonResponse> {
        const response = await this.exportQuestionnaireJsonRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async exportQuestionnaireTxtRaw(requestParameters: ExportQuestionnaireTxtRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OptionalForString>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling exportQuestionnaireTxt().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/internal/questionnaire/questionnaire/{uuid}/txt`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async exportQuestionnaireTxt(requestParameters: ExportQuestionnaireTxtRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OptionalForString> {
        const response = await this.exportQuestionnaireTxtRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves a questionnaire\'s current question
     * Retrieves a questionnaire\'s current question
     */
    async getCurrentQuestionRaw(requestParameters: GetCurrentQuestionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCurrentQuestionResponse>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling getCurrentQuestion().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/customer/questionnaire/questionnaire/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Retrieves a questionnaire\'s current question
     * Retrieves a questionnaire\'s current question
     */
    async getCurrentQuestion(requestParameters: GetCurrentQuestionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCurrentQuestionResponse> {
        const response = await this.getCurrentQuestionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Submits the answer to a questionnaire\'s current question
     * Submits the answer to a questionnaire\'s current question
     */
    async submitCurrentAnswerRaw(requestParameters: SubmitCurrentAnswerOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SubmitCurrentAnswerResponse>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling submitCurrentAnswer().'
            );
        }

        if (requestParameters['SubmitCurrentAnswerRequest'] == null) {
            throw new runtime.RequiredError(
                'SubmitCurrentAnswerRequest',
                'Required parameter "SubmitCurrentAnswerRequest" was null or undefined when calling submitCurrentAnswer().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/customer/questionnaire/questionnaire/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['SubmitCurrentAnswerRequest'],
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Submits the answer to a questionnaire\'s current question
     * Submits the answer to a questionnaire\'s current question
     */
    async submitCurrentAnswer(requestParameters: SubmitCurrentAnswerOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SubmitCurrentAnswerResponse> {
        const response = await this.submitCurrentAnswerRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
