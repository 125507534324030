import { createFileRoute } from "@tanstack/react-router";
import React from "react";
import { Api } from "src/api/api";
import CUSTOMER_CONTEXT from "src/context/customer";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { FullOfferPotential } from "src/api/generated";
import { Text } from "src/components/base/text";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "src/components/base/table";

/**
 * The properties for {@link CustomerUpselling}
 */
export type CustomerUpsellingProps = {};

/**
 * View for up-sale options
 */
function CustomerUpselling(props: CustomerUpsellingProps) {
    const { customer } = React.useContext(CUSTOMER_CONTEXT);
    const [t] = useTranslation("internal-customer-upselling");
    const [tg] = useTranslation();
    const [offers, setOffers] = React.useState<Array<FullOfferPotential>>([]);

    React.useEffect(() => {
        Api.internal.customers.offerPotentials.all(customer.uuid).then((res) => {
            res.match(
                (offer) => {
                    setOffers(offer.list);
                },
                (err) => toast.error(err.message),
            );
        });
    }, []);

    return (
        <div className={"flex flex-col gap-6"}>
            {offers.length === 0 ? (
                <Text>{t("description.no-offers")}</Text>
            ) : (
                <>
                    <Table dense={true}>
                        <TableHead>
                            <TableRow>
                                <TableHeader>{t("label.offer-suggestion")}</TableHeader>
                                <TableHeader>{t("label.type")}</TableHeader>
                                <TableHeader>{t("label.data")}</TableHeader>
                                {/* <TableHeader>Projekt</TableHeader>*/}
                                <TableHeader className={"w-0"}>
                                    <span className={"sr-only"}>{tg("accessibility.actions")}</span>
                                </TableHeader>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {offers.map((offer) => (
                                <TableRow>
                                    <TableCell>{offer.topic.name}</TableCell>
                                    <TableCell>
                                        {offer.topic.data.type === "Size" || offer.topic.data.type === "Custom"
                                            ? offer.topic.data.label
                                            : ""}
                                    </TableCell>
                                    <TableCell className={"max-w-52 text-wrap break-words"}>
                                        {offer.data.type === "Custom"
                                            ? offer.data.text
                                            : offer.data.type === "Size"
                                              ? offer.data.size
                                              : ""}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </>
            )}
        </div>
    );
}

export const Route = createFileRoute("/_internal/i/customers/$customerId/_customer/upselling")({
    component: CustomerUpselling,
});
