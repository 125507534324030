import { createFileRoute } from "@tanstack/react-router";

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "@tanstack/react-form";
import { Api } from "src/api/api";
import { toast } from "react-toastify";
import Form from "src/components/base/form";
import { Description, ErrorMessage, Field, FieldGroup, Fieldset, Label } from "src/components/base/fieldset";
import { Button } from "src/components/base/button";
import { Input } from "src/components/base/input";
import { Textarea } from "src/components/base/textarea";
import BlockUnsavedChanges from "src/components/base/unsaved-changes";
import HeadingLayout from "src/components/base/heading-layout";
import { Divider } from "src/components/base/divider";
import MeetingAttendees from "src/components/internal/meeting-attendees";
import UpsellOptions from "src/components/internal/upsell-options";
import { CreateOfferPotentialRequest, SimpleCustomerFile } from "src/api/generated";
import { Checkbox, CheckboxGroup } from "src/components/base/checkbox";

/**
 * The properties for {@link EnterDialogResultsForm}
 */
export type EnterDialogResultsFormProps = {};

/**
 * The form to enter dialog results
 */
function EnterDialogResultsForm(props: EnterDialogResultsFormProps) {
    const [t] = useTranslation("internal-enter-dialog-results-v1");
    const [tg] = useTranslation();
    const { taskId } = Route.useParams();

    const [presentation, setPresentation] = React.useState<SimpleCustomerFile | undefined>(undefined);

    const form = useForm({
        defaultValues: {
            debriefDate: "",
            debriefAttendees: {
                technical_lead: false,
                technical_assistant: false,
                management_assistant: false,
                project_manager: true,
            },
            skipDebrief: false,
            existingManagedServices: "",
            existingMedrSoc: "",
            // eslint-disable-next-line
            selectedOfferSuggestions: [] as Array<CreateOfferPotentialRequest & { name: string; label?: string }>,
            notes: "",
        },
        // eslint-disable-next-line
        onSubmit: async ({ formApi, value }) => {
            const res = await Api.internal.projects.pentest.setDialogResultsV1(taskId, {
                debrief_date: value.skipDebrief
                    ? { state: "Canceled" }
                    : value.debriefDate === ""
                      ? undefined
                      : {
                            state: "Scheduled",
                            date: new Date(value.debriefDate).toISOString(),
                        },
                debrief_attendees: value.debriefAttendees,
                existing_managed_services: value.existingManagedServices,
                existing_medr_soc: value.existingMedrSoc,
                offer_potentials: value.selectedOfferSuggestions.map((x) => ({ data: x.data, topic: x.topic })),
                notes: value.notes,
            });
            res.match(
                (res) => {
                    if (res.result === "Ok") {
                        window.history.back();
                    } else {
                        if (res.error.debrief_date) {
                            formApi.setFieldMeta("debriefDate", (meta) => {
                                meta.errors.push(tg("error.date-in-past"));
                                return meta;
                            });
                        }
                    }
                },
                (err) => toast.error(err.message),
            );
        },
    });
    const isDirty = form.useStore((store) => store.isDirty);
    const skipDebrief = form.useStore((store) => store.values.skipDebrief);
    const [customerName, setCustomerName] = React.useState<string>("");

    useEffect(() => {
        Api.internal.projects.pentest.getEnterResults(taskId).then((res) => {
            res.match(
                (res) => setPresentation(res.dialog_presentation),
                (err) => toast.error(err.message),
            );
        });
        Api.internal.tasks.getReady(taskId).then((res) => {
            res.match(
                (res) => {
                    if (res.project) {
                        setCustomerName(res.project.customer.legal_name);
                    }
                },
                (err) => toast.error(err.message),
            );
        });
    }, []);

    return (
        <HeadingLayout
            heading={tg("internal.enter-dialog-results.heading")}
            headingDescription={t("label.customer") + ": " + customerName}
            headingChildren={
                presentation && (
                    <Button
                        target={"_blank"}
                        href={`/api/frontend/v1/internal/customers/files/${presentation.uuid}`}
                        download={presentation.name}
                    >
                        {tg("button.download-pres")}
                    </Button>
                )
            }
        >
            <Form onSubmit={form.handleSubmit} className={"max-w-lg"}>
                <Fieldset>
                    <FieldGroup>
                        <form.Field name={"debriefDate"}>
                            {(fieldApi) => (
                                <Field>
                                    <Label>{tg("label.debrief-date")}</Label>
                                    <Input
                                        disabled={skipDebrief}
                                        autoFocus={true}
                                        type={"datetime-local"}
                                        value={fieldApi.state.value}
                                        invalid={fieldApi.state.meta.errors.length > 0}
                                        onChange={(e) => fieldApi.handleChange(e.target.value)}
                                    />
                                    {fieldApi.state.meta.errors.map((err) => (
                                        <ErrorMessage>{err}</ErrorMessage>
                                    ))}
                                </Field>
                            )}
                        </form.Field>

                        <form.Field name={"debriefAttendees"}>
                            {(fieldApi) => (
                                <MeetingAttendees
                                    skip={skipDebrief}
                                    technical_lead={fieldApi.state.value.technical_lead}
                                    technical_assistant={fieldApi.state.value.technical_assistant}
                                    management_assistant={fieldApi.state.value.management_assistant}
                                    project_manager={fieldApi.state.value.project_manager}
                                    onChange={(
                                        technical_lead,
                                        technical_assistant,
                                        management_assistant,
                                        project_manager,
                                    ) => {
                                        fieldApi.handleChange({
                                            ...fieldApi.state.value,
                                            project_manager: project_manager,
                                            technical_lead: technical_lead,
                                            technical_assistant: technical_assistant,
                                            management_assistant: management_assistant,
                                        });
                                    }}
                                />
                            )}
                        </form.Field>

                        <form.Field name={"skipDebrief"}>
                            {(fieldApi) => (
                                <Field>
                                    <Label>{tg("label.skip-debrief")}</Label>
                                    <CheckboxGroup>
                                        <Checkbox checked={fieldApi.state.value} onChange={fieldApi.handleChange} />
                                    </CheckboxGroup>
                                </Field>
                            )}
                        </form.Field>

                        <form.Field name={"existingManagedServices"}>
                            {(fieldApi) => (
                                <Field>
                                    <Label>{t("label.managed-services")}</Label>
                                    <Description>{t("description.enter-name")}</Description>
                                    <Input
                                        value={fieldApi.state.value}
                                        onChange={(e) => fieldApi.handleChange(e.target.value)}
                                    />
                                </Field>
                            )}
                        </form.Field>

                        <form.Field name={"existingMedrSoc"}>
                            {(fieldApi) => (
                                <Field>
                                    <Label>{t("label.medr-soc")}</Label>
                                    <Description>{t("description.enter-name")}</Description>
                                    <Input
                                        value={fieldApi.state.value}
                                        onChange={(e) => fieldApi.handleChange(e.target.value)}
                                    />
                                </Field>
                            )}
                        </form.Field>

                        <Divider />

                        <form.Field name={"selectedOfferSuggestions"}>
                            {(fieldApi) => (
                                <UpsellOptions
                                    selectedOfferSuggestions={fieldApi.state.value}
                                    handleChange={(list) => {
                                        fieldApi.handleChange(list);
                                    }}
                                />
                            )}
                        </form.Field>

                        <Divider />

                        <form.Field name={"notes"}>
                            {(fieldApi) => (
                                <Field>
                                    <Label>{tg("label.notes")}</Label>
                                    <Textarea
                                        value={fieldApi.state.value}
                                        onChange={(e) => fieldApi.handleChange(e.target.value)}
                                    />
                                </Field>
                            )}
                        </form.Field>

                        <Button type={"submit"} color={"blue"}>
                            {tg("button.submit")}
                        </Button>
                    </FieldGroup>
                </Fieldset>
            </Form>

            <BlockUnsavedChanges condition={isDirty} />
        </HeadingLayout>
    );
}

export const Route = createFileRoute("/_internal/i/forms/tasks/$taskId/enter-dialog-results-v1")({
    component: EnterDialogResultsForm,
});
