import { createFileRoute } from "@tanstack/react-router";

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Api, UUID } from "src/api/api";
import RunScript from "src/components/internal/scripts";
import { useForm } from "@tanstack/react-form";
import { toast } from "react-toastify";
import { Campaign } from "src/api/generated";
import { ErrorMessage, Field, Label, RequiredLabel } from "src/components/base/fieldset";
import { Combobox, ComboboxOption } from "src/components/base/combobox";
import { Checkbox, CheckboxField } from "src/components/base/checkbox";
import { Button } from "src/components/base/button";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "src/components/base/table";
import { ClipboardDocumentIcon } from "@heroicons/react/24/outline";

/**
 * The properties for {@link GenerateAttackStatus}
 */
export type GenerateAttackStatusProps = {};

/** The result of the script */
type GenerateAttackStatusResult = {
    /** Mail text */
    message: string;
};

/**
 * Run the script: Generate attack status
 */
function GenerateAttackStatus(props: GenerateAttackStatusProps) {
    const [t] = useTranslation("internal-scripts-generate-attack-status");
    const [tg] = useTranslation();

    const [runningScript, setRunningScript] = React.useState<UUID>();
    const [campaigns, setCampaigns] = React.useState<Array<Campaign>>([]);

    const [campaignSearch, setCampaignSearch] = React.useState("");

    const form = useForm({
        defaultValues: { campaign: null as null | Campaign, allowStoppedCampaigns: false },
        // eslint-disable-next-line
        onSubmit: async ({ value, formApi }) => {
            if (value.campaign === null) {
                formApi.setFieldMeta("campaign", (meta) => {
                    meta.errors.push(t("error.must-be-filled"));
                    return meta;
                });
                return;
            }

            const res = await Api.internal.lucy.runGenerateAttackStatus({
                campaign: value.campaign.id,
                allow_stopped_campaigns: value.allowStoppedCampaigns,
            });
            if (res.isErr) {
                toast.error(res.err.message);
                return;
            }

            setRunningScript(res.ok.uuid);
        },
    });

    /**
     * Fetch the campaigns from lucy
     */
    const fetchCampaigns = async () => {
        const res = await Api.internal.lucy.getCampaigns();
        if (res.isErr) {
            toast.error(res.err.message);
            return;
        }

        setCampaigns(
            res.ok.list.filter((c) => !c.name.startsWith("[Template]")).filter((c) => !c.name.startsWith("Training")),
        );
    };

    useEffect(() => {
        fetchCampaigns().then();
    }, []);

    const campaignOptions =
        campaignSearch === ""
            ? campaigns
            : campaigns.filter((campaign) => campaign.name.toLowerCase().includes(campaignSearch.toLowerCase()));

    return (
        <RunScript<GenerateAttackStatusResult>
            runningScript={runningScript}
            setRunningScript={setRunningScript}
            scriptKilled={() => {}}
            renderResult={(res) => (
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableHeader>{tg("accessibility.actions")}</TableHeader>
                            <TableHeader className={"w-0"}></TableHeader>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>{t("button.copy-mail")}</TableCell>
                            <TableCell>
                                <Button
                                    plain={true}
                                    onClick={async () => {
                                        await navigator.clipboard.writeText(res.message);
                                        toast.success(tg("toast.copied-to-clipboard"));
                                    }}
                                >
                                    <ClipboardDocumentIcon />
                                </Button>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            )}
            scriptName={t("heading.generate-attack-status")}
            onSubmit={form.handleSubmit}
        >
            <form.Field name={"campaign"}>
                {(fieldApi) => (
                    <Field>
                        <RequiredLabel>{t("label.campaign")}</RequiredLabel>
                        <Combobox
                            invalid={fieldApi.state.meta.errors.length > 0}
                            value={fieldApi.state.value}
                            onChange={fieldApi.handleChange}
                            onClose={() => setCampaignSearch("")}
                            onQueryChange={(query: string) => setCampaignSearch(query)}
                            queryDisplay={(q) => (q ? q.name : "")}
                        >
                            {campaignOptions.map((c) => (
                                <ComboboxOption key={c.id} value={c}>
                                    <Label>{c.name}</Label>
                                </ComboboxOption>
                            ))}
                        </Combobox>
                        {fieldApi.state.meta.errors.map((error) => (
                            <ErrorMessage>{error}</ErrorMessage>
                        ))}
                    </Field>
                )}
            </form.Field>

            <form.Field name={"allowStoppedCampaigns"}>
                {(fieldApi) => (
                    <Field>
                        <CheckboxField>
                            <Label>{t("label.allow-stopped-campaigns")}</Label>
                            <Checkbox checked={fieldApi.state.value} onChange={fieldApi.handleChange} />
                        </CheckboxField>
                    </Field>
                )}
            </form.Field>
        </RunScript>
    );
}

export const Route = createFileRoute("/_internal/i/phishing/scripts/generate-attack-status")({
    component: GenerateAttackStatus,
});
