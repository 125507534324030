import { createFileRoute } from "@tanstack/react-router";

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "@tanstack/react-form";
import { Api } from "src/api/api";
import { toast } from "react-toastify";
import Form from "src/components/base/form";
import { ErrorMessage, Field, FieldGroup, Fieldset, Label, RequiredLabel } from "src/components/base/fieldset";
import { Button } from "src/components/base/button";
import { EnterPentestKickoffResultsV0Request } from "src/api/generated";
import { Input } from "src/components/base/input";
import { Checkbox, CheckboxField, CheckboxGroup } from "src/components/base/checkbox";
import { Listbox, ListboxOption } from "src/components/base/listbox";
import { Textarea } from "src/components/base/textarea";
import BlockUnsavedChanges from "src/components/base/unsaved-changes";
import HeadingLayout from "src/components/base/heading-layout";

/**
 * The properties for {@link EnterKickoffResultsForm}
 */
export type EnterKickoffResultsFormProps = {};

/**
 * The form to enter kickoff results
 */
function EnterKickoffResultsForm(props: EnterKickoffResultsFormProps) {
    const [t] = useTranslation();
    const { taskId } = Route.useParams();

    const form = useForm({
        defaultValues: {
            dialog_date: "",
            scan_date: "",
            anti_scan: false,
            report_deadline: "",
            report_delivery: "Share" as EnterPentestKickoffResultsV0Request["report_delivery"],
            notes: "",
        },
        // eslint-disable-next-line
        onSubmit: async ({ formApi, value }) => {
            const res = await Api.internal.projects.pentest.setKickoffResultsV0(taskId, {
                anti_scan: value.anti_scan,
                dialog_date: new Date(value.dialog_date).toISOString(),
                ...{
                    report_deadline:
                        value.report_deadline === "" ? undefined : new Date(value.report_deadline).toISOString(),
                },
                report_delivery: value.report_delivery,
                scan_date: new Date(value.scan_date).toISOString(),
                notes: value.notes,
            });
            res.match(
                (res) => {
                    if (res.result === "Ok") {
                        window.history.back();
                    } else {
                        if (res.error.dialog_date) {
                            formApi.setFieldMeta("dialog_date", (meta) => {
                                meta.errors.push(t("error.date-in-past"));
                                return meta;
                            });
                        }
                        if (res.error.report_deadline) {
                            formApi.setFieldMeta("report_deadline", (meta) => {
                                meta.errors.push(t("error.date-in-past"));
                                return meta;
                            });
                        }
                        if (res.error.scan_date) {
                            formApi.setFieldMeta("scan_date", (meta) => {
                                meta.errors.push(t("error.date-in-past"));
                                return meta;
                            });
                        }
                        if (res.error.report_delivery) {
                            formApi.setFieldMeta("notes", (meta) => {
                                meta.errors.push(
                                    t("internal.enter-kickoff-results.error.report-delivery-without-notes"),
                                );
                                return meta;
                            });
                        }
                    }
                },
                (err) => toast.error(err.message),
            );
        },
    });
    const isDirty = form.useStore((store) => store.isDirty);

    const [customerName, setCustomerName] = React.useState<string>("");

    useEffect(() => {
        Api.internal.tasks.getReady(taskId).then((res) => {
            res.match(
                (res) => {
                    if (res.project) {
                        setCustomerName(res.project.customer.legal_name);
                    }
                },
                (err) => toast.error(err.message),
            );
        });
    }, []);

    return (
        <HeadingLayout
            heading={t("internal.enter-kickoff-results.heading")}
            headingDescription={t("label.customer") + ": " + customerName}
        >
            <Form onSubmit={form.handleSubmit} className={"max-w-lg"}>
                <Fieldset>
                    <FieldGroup>
                        <form.Field name={"dialog_date"}>
                            {(fieldApi) => (
                                <Field>
                                    <RequiredLabel>{t("label.dialog-date")}</RequiredLabel>
                                    <Input
                                        required={true}
                                        autoFocus={true}
                                        type={"datetime-local"}
                                        value={fieldApi.state.value}
                                        invalid={fieldApi.state.meta.errors.length > 0}
                                        onChange={(e) => fieldApi.handleChange(e.target.value)}
                                    />
                                    {fieldApi.state.meta.errors.map((err) => (
                                        <ErrorMessage>{err}</ErrorMessage>
                                    ))}
                                </Field>
                            )}
                        </form.Field>

                        <form.Field name={"scan_date"}>
                            {(fieldApi) => (
                                <Field>
                                    <RequiredLabel>{t("label.scan-date")}</RequiredLabel>
                                    <Input
                                        required={true}
                                        type={"datetime-local"}
                                        value={fieldApi.state.value}
                                        invalid={fieldApi.state.meta.errors.length > 0}
                                        onChange={(e) => fieldApi.handleChange(e.target.value)}
                                    />
                                    {fieldApi.state.meta.errors.map((err) => (
                                        <ErrorMessage>{err}</ErrorMessage>
                                    ))}
                                </Field>
                            )}
                        </form.Field>

                        <form.Field name={"anti_scan"}>
                            {(fieldApi) => (
                                <Field>
                                    <Label>{t("label.anti-scan-non-deactivatable")}</Label>
                                    <CheckboxGroup>
                                        <CheckboxField>
                                            <Checkbox
                                                checked={fieldApi.state.value}
                                                onChange={(e) => fieldApi.handleChange(e)}
                                            />
                                            <Label>{t("label.yes")}</Label>
                                        </CheckboxField>
                                    </CheckboxGroup>
                                </Field>
                            )}
                        </form.Field>

                        <form.Field name={"report_deadline"}>
                            {(fieldApi) => (
                                <Field>
                                    <Label>{t("label.report-date")}</Label>
                                    <Input
                                        type={"datetime-local"}
                                        value={fieldApi.state.value}
                                        invalid={fieldApi.state.meta.errors.length > 0}
                                        onChange={(e) => fieldApi.handleChange(e.target.value)}
                                    />
                                    {fieldApi.state.meta.errors.map((err) => (
                                        <ErrorMessage>{err}</ErrorMessage>
                                    ))}
                                </Field>
                            )}
                        </form.Field>

                        <form.Field name={"report_delivery"}>
                            {(fieldApi) => (
                                <Field>
                                    <RequiredLabel>{t("label.report-delivery-method")}</RequiredLabel>
                                    <Listbox
                                        value={fieldApi.state.value}
                                        onChange={(e: EnterPentestKickoffResultsV0Request["report_delivery"]) =>
                                            fieldApi.handleChange(e)
                                        }
                                    >
                                        <ListboxOption value={"Share"}>
                                            <Label>{t("label.delivery-share")}</Label>
                                        </ListboxOption>
                                        <ListboxOption value={"Postal"}>
                                            <Label>{t("label.delivery-postal")}</Label>
                                        </ListboxOption>
                                        <ListboxOption value={"Other"}>
                                            <Label>{t("label.delivery-other")}</Label>
                                        </ListboxOption>
                                    </Listbox>
                                </Field>
                            )}
                        </form.Field>

                        <form.Field name={"notes"}>
                            {(fieldApi) => (
                                <Field>
                                    <Label>{t("label.notes")}</Label>
                                    <Textarea
                                        value={fieldApi.state.value}
                                        onChange={(e) => fieldApi.handleChange(e.target.value)}
                                    />
                                </Field>
                            )}
                        </form.Field>

                        <Button type={"submit"} color={"blue"}>
                            {t("button.submit")}
                        </Button>
                    </FieldGroup>
                </Fieldset>
            </Form>

            <BlockUnsavedChanges condition={isDirty} />
        </HeadingLayout>
    );
}

export const Route = createFileRoute("/_internal/i/forms/tasks/$taskId/enter-kickoff-results-v0")({
    component: EnterKickoffResultsForm,
});
