import { createFileRoute } from "@tanstack/react-router";

import React, { useEffect } from "react";
import { Api, UUID } from "src/api/api";
import { toast } from "react-toastify";
import { FullQuestionnaireQuestion, SimpleQuestionnaireItem, SubmitCurrentAnswerRequest } from "src/api/generated";
import Question from "src/components/questionnaire/question";
import HeadingLayout from "src/components/base/heading-layout";
import { Heading } from "src/components/base/heading";
import { Text } from "src/components/base/text";
import { AnimatePresence, motion } from "framer-motion";
import { Button } from "src/components/base/button";
import { ChevronLeftIcon } from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";

/** Helper type to bundle all relevant information for a question */
type CurrQuestion = {
    /** The question itself */
    question: FullQuestionnaireQuestion;
    /** The group the question is in */
    group: SimpleQuestionnaireItem;
    /** The template the question belongs to */
    template: SimpleQuestionnaireItem;
};

/**
 * The properties for {@link Questionnaire}
 */
export type QuestionnaireProps = {};

/**
 * A questionnaire for the customer
 */
export default function Questionnaire(props: QuestionnaireProps) {
    const { taskId } = Route.useParams();
    const [questionnaireId, setQuestionnaireId] = React.useState<string>();

    const [tg] = useTranslation();
    const [t] = useTranslation("customer");

    const [currQuestion, setCurrQuestion] = React.useState<CurrQuestion | "completed">();

    /**
     * Retrieve the current question
     *
     * @param questionnaireId UUID of Questionnaire
     */
    const getCurrQuestion = async (questionnaireId: UUID) => {
        const res = await Api.customer.questionnaires.getCurrQuestion(questionnaireId);

        res.match(
            (res) => {
                if (res.result === "Question") {
                    setCurrQuestion({
                        group: res.group,
                        template: res.template,
                        question: res.question,
                    });
                } else if (res.result === "Completed") {
                    setCurrQuestion("completed");
                } else {
                    toast.error("Questionnaire question was not found");
                }
            },
            (err) => toast.error(err.message),
        );
    };

    /**
     * Submit the answer to the current question
     *
     * @param submit The request to submit
     */
    const submitQuestion = async (submit: SubmitCurrentAnswerRequest) => {
        if (questionnaireId === undefined) {
            return;
        }

        const res = await Api.customer.questionnaires.submitCurrQuestion(questionnaireId, submit);
        res.match(
            (res) => {
                if (res === "Ok") {
                    questionnaireId && getCurrQuestion(questionnaireId).then();
                }
            },
            (err) => toast.error(err.message),
        );
    };

    useEffect(() => {
        Api.customer.tasks.getReady(taskId).then((res) => {
            res.match(
                (res) => {
                    if (res.data.kind === "FillQuestionnaireV0") {
                        getCurrQuestion(res.data.questionnaire).then();
                        setQuestionnaireId(res.data.questionnaire);
                    }
                },
                (err) => toast.error(err.message),
            );
        });
    }, []);

    if (currQuestion === "completed") {
        return (
            <div className="flex flex-col gap-4">
                <Text>{t("questionnaire.description.questionnaire-finished")}</Text>
                <Button className={"place-self-start"} color={"blue"} onClick={() => history.back()}>
                    {tg("button.back-to-overview")}
                </Button>
            </div>
        );
    }

    return (
        currQuestion !== undefined && (
            <>
                <Button
                    plain={true}
                    onClick={() => {
                        history.back();
                    }}
                >
                    <ChevronLeftIcon />
                    <Text>{tg("button.back-to-overview")}</Text>
                </Button>

                <HeadingLayout
                    className={"mt-6"}
                    heading={currQuestion.template.title}
                    headingDescription={currQuestion.template.description}
                >
                    <div className={"overflow-hidden"}>
                        <div className={"flex flex-col gap-3"}>
                            <Heading>{currQuestion.group.title}</Heading>
                            <Text>{currQuestion.group.description}</Text>
                        </div>
                        <AnimatePresence mode={"wait"} initial={false}>
                            <motion.div
                                initial={{ x: 300, opacity: 0 }}
                                animate={{ x: 0, opacity: 1 }}
                                exit={{ x: -300, opacity: 0 }}
                                className={"mt-6"}
                                key={currQuestion.question.uuid}
                            >
                                <Question
                                    question={currQuestion.question}
                                    onNext={(answer) => submitQuestion(answer)}
                                />
                            </motion.div>
                        </AnimatePresence>
                    </div>
                </HeadingLayout>
            </>
        )
    );
}

export const Route = createFileRoute("/_customer/c/forms/tasks/$taskId/questionnaire")({
    component: Questionnaire,
});
