/* tslint:disable */
/* eslint-disable */
/**
 * Unnamed API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiErrorResponse,
  ListForFullOfferPotential,
} from '../models/index';

export interface DeleteOfferPotentialsRequest {
    uuid: string;
}

export interface GetCustomerOfferPotentialsRequest {
    uuid: string;
}

/**
 * 
 */
export class CustomerOfferPotentialsApi extends runtime.BaseAPI {

    /**
     * Deletes an offer potential
     * Deletes an offer potential
     */
    async deleteOfferPotentialsRaw(requestParameters: DeleteOfferPotentialsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling deleteOfferPotentials().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/internal/customers/offer-potentials/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes an offer potential
     * Deletes an offer potential
     */
    async deleteOfferPotentials(requestParameters: DeleteOfferPotentialsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteOfferPotentialsRaw(requestParameters, initOverrides);
    }

    /**
     * Retrieves a customer\'s offer potentials  The results are presorted by date.
     * Retrieves a customer\'s offer potentials
     */
    async getCustomerOfferPotentialsRaw(requestParameters: GetCustomerOfferPotentialsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListForFullOfferPotential>> {
        if (requestParameters['uuid'] == null) {
            throw new runtime.RequiredError(
                'uuid',
                'Required parameter "uuid" was null or undefined when calling getCustomerOfferPotentials().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/internal/customers/{uuid}/offer-potentials`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters['uuid']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Retrieves a customer\'s offer potentials  The results are presorted by date.
     * Retrieves a customer\'s offer potentials
     */
    async getCustomerOfferPotentials(requestParameters: GetCustomerOfferPotentialsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListForFullOfferPotential> {
        const response = await this.getCustomerOfferPotentialsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
