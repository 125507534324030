import React from "react";
import { clsx } from "clsx";
import { EnvelopeIcon, UserIcon } from "@heroicons/react/20/solid";
import { PhoneIcon } from "@heroicons/react/16/solid";
import UserAvatar from "./user-avatar";
import { Badge } from "./base/badge";
import { useTranslation } from "react-i18next";
import { SimpleUser } from "../api/generated";

/**
 * The properties for {@link ContactCard}
 */
export type ContactCardProps = {
    /** The contact that should be displayed */
    contact: {
        /** Name of the user */
        name: string;
        /** Mail of the contact */
        mail: string;
        /** Contact was already invited */
        invited?: boolean;
        /** Phone of the user */
        phone?: string;
        /** The user account of the contact */
        user?: SimpleUser;
    };
    /** Hide the badges */
    hideBadges?: boolean;
    /** Actions that should be rendered */
    actions?: React.ReactNode;
    /** Optional classname */
    className?: string;
};

/**
 * A contact card that displays image and contact details of a user
 */
export default function ContactCard(props: ContactCardProps) {
    const [t] = useTranslation();
    const { contact, actions } = props;

    return (
        <div
            className={clsx(
                props.className,
                // Base
                "grid grid-cols-[auto_1fr_auto] items-center gap-x-6 gap-y-3 rounded-lg border p-3 pl-6",
                // Colors
                "shadow dark:border-zinc-800 dark:bg-zinc-900",
            )}
        >
            <UserAvatar
                user={{
                    name: contact.name,
                    has_profile_image: contact.user ? contact.user.has_profile_image : false,
                    uuid: contact.user ? contact.user.uuid : undefined,
                }}
                className={"size-16"}
            />
            <div className={"flex w-full flex-col"}>
                <span className={"text-sm font-medium dark:text-zinc-200"}>{contact.name}</span>
                {!props.hideBadges && (
                    <div>
                        {contact.user && (
                            <Badge className={"mt-1.5 w-fit"} color={"blue"}>
                                <UserIcon className={"size-3"} />
                                <span className={"text-xs"}>{t("label.has-account")}</span>
                            </Badge>
                        )}
                        {contact.invited && (
                            <Badge className={"mt-1.5 w-fit"} color={"cyan"}>
                                <UserIcon className={"size-3"} />
                                <span className={"text-xs"}>{t("label.contact-invited")}</span>
                            </Badge>
                        )}
                    </div>
                )}
            </div>
            {actions}
            <div className={"col-span-2 flex w-full flex-col gap-1"}>
                <a
                    href={`mailto:${contact.mail}`}
                    className={
                        "flex items-center gap-2 text-xs text-zinc-600 transition-colors duration-100 hover:text-blue-500 dark:text-zinc-400"
                    }
                >
                    <EnvelopeIcon className={"size-4"} />
                    <span>{contact.mail}</span>
                </a>
                {contact.phone !== "" && (
                    <a
                        href={`tel:${contact.phone}`}
                        className={
                            "flex items-center gap-2 text-xs text-zinc-600 transition-colors duration-100 hover:text-blue-500 dark:text-zinc-400"
                        }
                    >
                        <PhoneIcon className={"size-4"} />
                        <span>{contact.phone}</span>
                    </a>
                )}
            </div>
        </div>
    );
}
