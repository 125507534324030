import { createFileRoute } from "@tanstack/react-router";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { DescriptionDetails, DescriptionList, DescriptionTerm } from "src/components/base/description-list";
import { Subheading } from "src/components/base/heading";
import CUSTOMER_CONTEXT from "../../../../../../context/customer";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import ContactCard from "../../../../../../components/contact-card";
import { Dropdown, DropdownButton, DropdownItem, DropdownLabel, DropdownMenu } from "src/components/base/dropdown";
import { Dialog, DialogActions, DialogBody, DialogTitle } from "src/components/base/dialog";
import { Field } from "@headlessui/react";
import { Combobox, ComboboxOption } from "src/components/base/combobox";
import { Button } from "src/components/base/button";
import Form from "../../../../../../components/base/form";
import { InternalTask, InternalUser, SimpleUser } from "src/api/generated";
import { Api } from "src/api/api";
import { toast } from "react-toastify";
import { Label } from "src/components/base/fieldset";
import { Avatar } from "src/components/base/avatar";
import PentestCard from "../../../../../../components/pentest-card";
import USER_CONTEXT from "src/context/user";
import { Text } from "src/components/base/text";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "src/components/base/table";
import { sortDates } from "src/utils/sorter";
import { displayDeadline } from "src/utils/tasks";
import { Badge } from "src/components/base/badge";
import { Checkbox, CheckboxField } from "src/components/base/checkbox";

/**
 * The properties for {@link SingleCustomer}
 */
export type SingleCustomerProps = {};

/**
 * Manage all checkbox states
 */
type CheckboxStates = {
    /** if overdue customer tasks are shown */
    showOverdue: boolean;
    /** if done tasks are shown */
    showDone: boolean;
    /** if running tasks are shown */
    showRunning: boolean;
};

const CHECKBOXES_LS = "internal.customer.general.tasks";

/**
 * Get initial Checkbox state
 *
 * @returns initial checkbox state
 */
const getCheckboxState = () => {
    const checkboxes = localStorage.getItem(CHECKBOXES_LS);

    if (!checkboxes) {
        return undefined;
    }

    const state: CheckboxStates = JSON.parse(checkboxes);
    return state;
};

/**
 * The view to display the properties of a single customer
 */
export default function SingleCustomer(props: SingleCustomerProps) {
    const [t] = useTranslation();
    const [tg] = useTranslation("pm-view");
    const [tc] = useTranslation("customer");
    const { customer, reset } = React.useContext(CUSTOMER_CONTEXT);
    const { user } = React.useContext(USER_CONTEXT);

    const [projectManagers, setProjectManagers] = React.useState<Array<InternalUser>>([]);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [changePm, setChangePm] = React.useState<SimpleUser | null>(null);
    const [projectManagerQuery, setProjectManagerQuery] = React.useState("");

    const [tasks, setTasks] = React.useState<Array<InternalTask>>([]);
    const state = getCheckboxState();
    const [checkboxes, setCheckboxes] = React.useState<CheckboxStates | undefined>(state);

    /**
     * Update checkbox state in local storage and React
     *
     * @param update new state of checkboxes
     */
    const updateCheckboxState = (update: CheckboxStates) => {
        localStorage.setItem(CHECKBOXES_LS, JSON.stringify(update));
        setCheckboxes(update);
    };

    useEffect(() => {
        if (!localStorage.getItem(CHECKBOXES_LS)) {
            updateCheckboxState({
                showRunning: true,
                showOverdue: true,
                showDone: false,
            });
        }

        Api.internal.internalUsers.all().then((res) =>
            res.match(
                (list) => setProjectManagers(list.list.filter((user) => user.groups.project_management)),
                (err) => toast.error(err.message),
            ),
        );

        Api.internal.tasks.allFiltered({ user_role: "Customer", customer_uuid: customer.uuid }).then((res) => {
            res.match(
                (tasks) =>
                    setTasks(
                        tasks.list.filter((t) => {
                            return t.state !== "Waiting";
                        }),
                    ),
                (err) => toast.error(err.message),
            );
        });
    }, []);

    const filteredPMs =
        projectManagerQuery === ""
            ? projectManagers
            : projectManagers.filter((pm) => pm.display_name.toLowerCase().includes(projectManagerQuery.toLowerCase()));

    /**
     * Api call to change customer PM
     */
    const changeProjectManager = () => {
        changePm &&
            Api.internal.customers.update(customer.uuid, { project_manager: changePm.uuid }).then((res) =>
                res.match(
                    () => reset(),
                    (err) => toast.error(err.message),
                ),
            );
    };

    /**
     * Function that returns the right Badge for the state of their task
     *
     * @param task task
     *
     * @returns Badge with state
     */
    function displayState(task: InternalTask) {
        const state = task.state;
        if (state === "Finished") {
            return <Badge color={"green"}>{tc("pentest-project.badge.done")}</Badge>;
        } else if (state === "Ready") {
            const day_after_tomorrow = new Date(
                (Math.floor(new Date().getTime() / 86400000) + 2) * 1000 * 60 * 60 * 24,
            );
            if (
                task.deadline &&
                new Date(task.deadline).getTime() <= day_after_tomorrow.getTime() &&
                new Date(task.deadline).getTime() >= new Date().getTime()
            ) {
                return <Badge color={"sky"}>{tc("pentest-project.badge.due")}</Badge>;
            }
            if (task.deadline && new Date(task.deadline).getTime() < new Date().getTime()) {
                return <Badge color={"red"}>{tc("pentest-project.badge.overdue")}</Badge>;
            }
            return <Badge color={"blue"}>{tc("pentest-project.badge.running")}</Badge>;
        } else {
            return <Badge color={"zinc"}>{state}</Badge>;
        }
    }

    if (!checkboxes) {
        return undefined;
    }

    return (
        <div className={"flex flex-col gap-6"}>
            <DescriptionList className={"max-w-2xl"}>
                <DescriptionTerm>{t("label.legal-business-name")}</DescriptionTerm>
                <DescriptionDetails>{customer.legal_name}</DescriptionDetails>

                {/*   <DescriptionTerm>{t("label.created-at")}</DescriptionTerm>
                <DescriptionDetails>{new Date(customer.created_at).toLocaleDateString()}</DescriptionDetails>

                <DescriptionTerm>{t("label.created-by")}</DescriptionTerm>
                <DescriptionDetails>
                    {customer.created_by ? (
                        <UserInfo displayName={customer.created_by.display_name} mail={customer.created_by.mail} />
                    ) : (
                        <span>{t("label.anonymous")}</span>
                    )}
                </DescriptionDetails>

                <DescriptionTerm>{t("label.approved-at")}</DescriptionTerm>
                <DescriptionDetails>{new Date(customer.approved_at).toLocaleDateString()}</DescriptionDetails>

                <DescriptionTerm>{t("label.approved-by")}</DescriptionTerm>
                <DescriptionDetails>
                    {customer.approved_by ? (
                        <UserInfo displayName={customer.approved_by.display_name} mail={customer.approved_by.mail} />
                    ) : (
                        <span>{t("label.anonymous")}</span>
                    )}
                </DescriptionDetails> */}

                <DescriptionTerm>{t("label.notes")}</DescriptionTerm>
                <DescriptionDetails>{customer.notes}</DescriptionDetails>
            </DescriptionList>
            <div className={"grid sm:grid-cols-2 xl:grid-cols-3"}>
                <div className={"flex flex-col gap-3"}>
                    <Subheading>{t("label.project-manager")}</Subheading>
                    <ContactCard
                        key={customer.project_manager.uuid}
                        contact={{
                            name: customer.project_manager.display_name,
                            mail: customer.project_manager.mail,
                            user: customer.project_manager,
                            phone: customer.project_manager.phone,
                        }}
                        actions={
                            <>
                                {((user.permissions.role === "Internal" && user.permissions.groups.power_user) ||
                                    (user.permissions.role === "Internal" &&
                                        user.permissions.groups.project_management)) && (
                                    <Dropdown>
                                        <DropdownButton plain={true}>
                                            <span className={"sr-only"}>{t("accessibility.actions")}</span>
                                            <EllipsisVerticalIcon />
                                        </DropdownButton>
                                        <DropdownMenu anchor={"bottom end"}>
                                            <DropdownItem
                                                onClick={() => {
                                                    setOpenDialog(true);
                                                    setChangePm(customer.project_manager);
                                                }}
                                            >
                                                <DropdownLabel>{t("button.change")}</DropdownLabel>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                )}
                            </>
                        }
                    />
                </div>
            </div>
            <div className={"flex flex-col gap-3"}>
                <Subheading>{t("label.projects")}</Subheading>
                {customer.projects.length === 0 ? (
                    <Text>{t("internal.customer.description.no-projects")}</Text>
                ) : (
                    <div className={"grid grid-cols-4 gap-6"}>
                        {customer.projects
                            .sort((a, b) =>
                                a.archived === b.archived
                                    ? a.created_at < b.created_at
                                        ? -1
                                        : 1
                                    : a.archived < b.archived
                                      ? -1
                                      : 1,
                            )
                            .map((project) =>
                                project.kind === "Pentest" ? <PentestCard projectId={project.uuid} /> : undefined,
                            )}
                    </div>
                )}
            </div>
            <div className={"mb-6 mt-6 flex flex-col gap-3"}>
                <Subheading>{t("internal.customer.heading.tasks")}</Subheading>
                {tasks.length === 0 ? (
                    <Text>{t("internal.customer.description.no-tasks")}</Text>
                ) : (
                    <>
                        <div className={"flex gap-6"}>
                            <CheckboxField>
                                <Checkbox
                                    color={"blue"}
                                    checked={checkboxes.showRunning}
                                    onChange={(e) =>
                                        updateCheckboxState({
                                            ...checkboxes,
                                            showRunning: e,
                                        })
                                    }
                                />
                                <Label>{tc("pentest-project.badge.running")}</Label>
                            </CheckboxField>
                            <CheckboxField>
                                <Checkbox
                                    color={"blue"}
                                    checked={checkboxes.showOverdue}
                                    onChange={(e) =>
                                        updateCheckboxState({
                                            ...checkboxes,
                                            showOverdue: e,
                                        })
                                    }
                                />
                                <Label>{tc("pentest-project.badge.overdue")}</Label>
                            </CheckboxField>
                            <CheckboxField>
                                <Checkbox
                                    color={"blue"}
                                    checked={checkboxes.showDone}
                                    onChange={(e) =>
                                        updateCheckboxState({
                                            ...checkboxes,
                                            showDone: e,
                                        })
                                    }
                                />
                                <Label>{tc("pentest-project.badge.done")}</Label>
                            </CheckboxField>
                        </div>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableHeader>{t("label.state")}</TableHeader>
                                    <TableHeader>{t("label.due-until")}</TableHeader>
                                    <TableHeader>{t("label.task")}</TableHeader>
                                    <TableHeader>{t("label.next-appointment")}</TableHeader>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tasks
                                    .sort((a, b) =>
                                        sortDates(
                                            a.deadline ? new Date(a.deadline) : undefined,
                                            b.deadline ? new Date(b.deadline) : undefined,
                                        ),
                                    )
                                    .filter((t) => {
                                        if (t.state === "Finished") {
                                            return checkboxes.showDone;
                                        } else {
                                            return true;
                                        }
                                    })
                                    .filter((t) => {
                                        if (t.state === "Ready") {
                                            const overdue =
                                                t.deadline && new Date(t.deadline).getTime() < new Date().getTime();

                                            if (overdue) {
                                                return checkboxes.showOverdue;
                                            } else {
                                                return checkboxes.showRunning;
                                            }
                                        } else {
                                            return true;
                                        }
                                    })
                                    .map((task) =>
                                        task.data.kind === "FillPentestTargetsV0" ? (
                                            <TableRow
                                                key={task.uuid}
                                                href={"/i/pentests/$projectId/general"}
                                                params={{ projectId: task.project.uuid }}
                                            >
                                                <TableCell>{displayState(task)}</TableCell>
                                                <TableCell
                                                    title={task.deadline && new Date(task.deadline).toLocaleString()}
                                                >
                                                    {task.state !== "Finished"
                                                        ? task.deadline && displayDeadline(new Date(task.deadline))
                                                        : "-"}
                                                </TableCell>
                                                <TableCell>{t("tasks.label.fill-pentest-targets")}</TableCell>
                                                <TableCell
                                                    title={
                                                        task.project.next_meeting_date &&
                                                        new Date(task.project.next_meeting_date).toLocaleString()
                                                    }
                                                >
                                                    {task.state === "Finished" ? (
                                                        "-"
                                                    ) : (
                                                        <>
                                                            {task.project.next_meeting}
                                                            {task.project.next_meeting ? ": " : ""}
                                                            {task.project.next_meeting_date
                                                                ? displayDeadline(
                                                                      new Date(task.project.next_meeting_date),
                                                                  )
                                                                : tg("label.not-scheduled")}
                                                        </>
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        ) : task.data.kind === "FillQuestionnaireV0" ? (
                                            <TableRow
                                                key={task.uuid}
                                                href={"/i/pentests/$projectId/general"}
                                                params={{ projectId: task.project.uuid }}
                                            >
                                                <TableCell>{displayState(task)}</TableCell>
                                                <TableCell
                                                    title={task.deadline && new Date(task.deadline).toLocaleString()}
                                                >
                                                    {task.state !== "Finished"
                                                        ? task.deadline && displayDeadline(new Date(task.deadline))
                                                        : "-"}
                                                </TableCell>
                                                <TableCell>
                                                    {task.data.usage === "AssessmentOrganizational"
                                                        ? t("tasks.label.fill-questionnaire-organizational")
                                                        : t("tasks.label.fill-questionnaire-tech")}
                                                </TableCell>
                                                <TableCell
                                                    title={
                                                        task.project.next_meeting_date &&
                                                        new Date(task.project.next_meeting_date).toLocaleString()
                                                    }
                                                >
                                                    {task.state === "Finished" ? (
                                                        "-"
                                                    ) : (
                                                        <>
                                                            {task.project.next_meeting}
                                                            {task.project.next_meeting ? ": " : ""}
                                                            {task.project.next_meeting_date
                                                                ? displayDeadline(
                                                                      new Date(task.project.next_meeting_date),
                                                                  )
                                                                : tg("label.not-scheduled")}
                                                        </>
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        ) : task.data.kind === "AssignProjectContactsV0" ? (
                                            <TableRow
                                                key={task.uuid}
                                                href={"/i/pentests/$projectId/general"}
                                                params={{ projectId: task.project.uuid }}
                                            >
                                                <TableCell>{displayState(task)}</TableCell>
                                                <TableCell
                                                    title={task.deadline && new Date(task.deadline).toLocaleString()}
                                                >
                                                    {task.state !== "Finished"
                                                        ? task.deadline && displayDeadline(new Date(task.deadline))
                                                        : "-"}
                                                </TableCell>
                                                <TableCell>{t("tasks.label.assign-project-contacts")}</TableCell>
                                                <TableCell
                                                    title={
                                                        task.project.next_meeting_date &&
                                                        new Date(task.project.next_meeting_date).toLocaleString()
                                                    }
                                                >
                                                    {task.state === "Finished" ? (
                                                        "-"
                                                    ) : (
                                                        <>
                                                            {task.project.next_meeting}
                                                            {task.project.next_meeting ? ": " : ""}
                                                            {task.project.next_meeting_date
                                                                ? displayDeadline(
                                                                      new Date(task.project.next_meeting_date),
                                                                  )
                                                                : tg("label.not-scheduled")}
                                                        </>
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        ) : (
                                            <TableRow
                                                key={task.uuid}
                                                href={"/i/pentests/$projectId/general"}
                                                params={{ projectId: task.project.uuid }}
                                            >
                                                <TableCell>{displayState(task)}</TableCell>
                                                <TableCell
                                                    title={task.deadline && new Date(task.deadline).toLocaleString()}
                                                >
                                                    {task.state !== "Finished"
                                                        ? task.deadline && displayDeadline(new Date(task.deadline))
                                                        : "-"}
                                                </TableCell>
                                                <TableCell>{JSON.stringify(task.data)}</TableCell>
                                                <TableCell
                                                    title={
                                                        task.project.next_meeting_date &&
                                                        new Date(task.project.next_meeting_date).toLocaleString()
                                                    }
                                                >
                                                    {task.state === "Finished" ? (
                                                        "-"
                                                    ) : (
                                                        <>
                                                            {task.project.next_meeting}
                                                            {task.project.next_meeting ? ": " : ""}
                                                            {task.project.next_meeting_date
                                                                ? displayDeadline(
                                                                      new Date(task.project.next_meeting_date),
                                                                  )
                                                                : tg("label.not-scheduled")}
                                                        </>
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        ),
                                    )}
                            </TableBody>
                        </Table>
                    </>
                )}
            </div>

            {openDialog && (
                <Dialog
                    open={true}
                    onClose={() => {
                        setChangePm(null);
                        setOpenDialog(false);
                    }}
                >
                    <Form
                        onSubmit={() => {
                            changeProjectManager();
                            setChangePm(null);
                            setOpenDialog(false);
                        }}
                    >
                        <DialogTitle>{t("internal.customer.heading.change-pm")}</DialogTitle>
                        <DialogBody>
                            <Field>
                                <Combobox
                                    value={changePm}
                                    onChange={(pm) => {
                                        setChangePm(pm);
                                    }}
                                    onClose={() => setProjectManagerQuery("")}
                                    queryDisplay={(pm) => (pm ? pm.display_name : "")}
                                    onQueryChange={(query) => setProjectManagerQuery(query)}
                                >
                                    {filteredPMs.map((pm) => (
                                        <ComboboxOption key={pm.uuid} value={pm}>
                                            <Label className={"flex items-center gap-2"}>
                                                <Avatar
                                                    square={true}
                                                    className={"size-5"}
                                                    initials={pm.display_name
                                                        .split(" ")
                                                        .map((c) => c[0])
                                                        .join("")}
                                                />
                                                <span>{pm.display_name}</span>
                                            </Label>
                                        </ComboboxOption>
                                    ))}
                                </Combobox>
                            </Field>
                        </DialogBody>
                        <DialogActions>
                            <Button type={"submit"} color={"blue"}>
                                {t("button.change")}
                            </Button>
                        </DialogActions>
                    </Form>
                </Dialog>
            )}
        </div>
    );
}

export const Route = createFileRoute("/_internal/i/customers/$customerId/_customer/general")({
    component: SingleCustomer,
});
