import { createFileRoute } from "@tanstack/react-router";

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Api, UUID } from "src/api/api";
import { toast } from "react-toastify";
import { AddPentestTargetRequest, FullPentestTargets, PentestTargetsPriority } from "src/api/generated";
import HeadingLayout from "src/components/base/heading-layout";
import { Button } from "src/components/base/button";
import { EllipsisVerticalIcon, PlusIcon } from "@heroicons/react/20/solid";
import { Text } from "src/components/base/text";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "src/components/base/table";
import { Dropdown, DropdownButton, DropdownItem, DropdownMenu } from "src/components/base/dropdown";
import { Badge } from "src/components/base/badge";
import { ErrorMessage, Field } from "src/components/base/fieldset";
import { Listbox, ListboxOption } from "src/components/base/listbox";
import { useForm } from "@tanstack/react-form";
import ipaddr from "ipaddr.js";
import { validateDomain } from "src/utils/validators";
import { Input } from "src/components/base/input";
import Form from "src/components/base/form";
import { Dialog, DialogActions, DialogDescription, DialogTitle } from "src/components/base/dialog";

/**
 * The properties for {@link PentestTargets}
 */
export type PentestTargetsProps = {};

/**
 * The form to view and edit the targets of a pentest
 */
function PentestTargets(props: PentestTargetsProps) {
    const { taskId } = Route.useParams();
    const [targetId, setTargetId] = useState<string>();
    const [tg] = useTranslation();
    const [t] = useTranslation("customer");

    const [targets, setTargets] = React.useState<FullPentestTargets>();
    const [openSubmit, setOpenSubmit] = React.useState(false);
    const [openDirty, setOpenDirty] = React.useState(false);

    const addForm = useForm({
        defaultValues: {
            type: "Ip" as AddPentestTargetRequest["kind"],
            data: "",
            priority: "Normal" as PentestTargetsPriority,
            comment: "",
        },
        // eslint-disable-next-line
        onSubmit: async ({ value, formApi }) => {
            if (targetId === undefined) {
                return;
            }

            (
                await Api.customer.pentest.targets.add(targetId, {
                    ip: value.data,
                    domain: value.data,
                    priority: value.priority,
                    comment: value.comment,
                    kind: value.type,
                })
            ).match(
                (res) => {
                    if (res.result === "Ok") {
                        formApi.reset();
                        refreshTargets(targetId);
                    } else {
                        if (res.error.ip) {
                            formApi.setFieldMeta("data", (meta) => {
                                meta.errors.push(t("targets.error.invalid-ip"));
                                return meta;
                            });
                        }

                        if (res.error.already_exists) {
                            formApi.setFieldMeta("data", (meta) => {
                                meta.errors.push(t("targets.error.already-exists"));
                                return meta;
                            });
                        }
                    }
                },
                (err) => toast.error(err.message),
            );
        },
    });

    const data = addForm.useStore((store) => store.values.data);

    /**
     * Delete an entry from the targets
     *
     * @param uuid The entry to delete
     */
    const deleteEntry = (uuid: UUID) => {
        if (targetId === undefined) {
            return;
        }

        Api.customer.pentest.targets.remove(uuid).then((res) =>
            res.match(
                () => refreshTargets(targetId),
                (err) => toast.error(err.message),
            ),
        );
    };

    /**
     * Helper to refresh the targets
     *
     * @param targetId UUID of targets
     */
    const refreshTargets = (targetId: UUID) => {
        Api.customer.pentest.targets.get(targetId).then((res) =>
            res.match(
                (targets) => setTargets(targets.optional),
                (err) => toast.error(err.message),
            ),
        );
    };

    /**
     * Submit the targets
     */
    const submitTargets = () => {
        if (targetId === undefined) {
            return;
        }
        Api.customer.pentest.targets.submit(targetId).then((res) =>
            res.match(
                () => history.back(),
                (err) => toast.error(err.message),
            ),
        );
    };

    useEffect(() => {
        Api.customer.tasks.getReady(taskId).then((res) => {
            res.match(
                (res) => {
                    if (res.data.kind === "FillPentestTargetsV0") {
                        setTargetId(res.data.pentest_targets);
                        refreshTargets(res.data.pentest_targets);
                    }
                },
                (err) => toast.error(err.message),
            );
        });
    }, []);

    /**
     * Helper to translate priorities
     *
     * @param priority The priority to translate
     * @returns The translated string
     */
    const translatePriority = (priority: PentestTargetsPriority): string => {
        if (priority === "Low") {
            return tg("label.priority-low");
        } else if (priority === "Normal") {
            return tg("label.priority-normal");
        } else {
            return tg("label.priority-high");
        }
    };

    const entries = targets
        ? targets.ips
              .map((ip) => ({
                  uuid: ip.uuid,
                  type: tg("label.ip"),
                  data: ip.ip,
                  priority_type: ip.priority,
                  priority: translatePriority(ip.priority),
                  comment: ip.comment,
              }))
              .concat(
                  targets.domains.map((domain) => ({
                      uuid: domain.uuid,
                      type: tg("label.domain"),
                      data: domain.domain,
                      priority_type: domain.priority,
                      priority: translatePriority(domain.priority),
                      comment: domain.comment,
                  })),
              )
        : [];

    return (
        <div className={"flex w-full flex-col gap-6"}>
            {/*
             <Button
             plain={true}
             href={"/c/projects/pentests/$projectId/general"}
             params={{ projectId }}
             className={"self-start"}
             >
             <ChevronLeftIcon />
             <Text>{t("targets.button.back-to-overview")}</Text>
             </Button>
             */}

            <HeadingLayout
                heading={t("targets.heading.targets")}
                headingChildren={
                    targets &&
                    !targets.submitted && (
                        <div className={"flex justify-end"}>
                            <Button
                                color={"blue"}
                                onClick={() => {
                                    if (data !== "") {
                                        setOpenDirty(true);
                                    } else {
                                        setOpenSubmit(true);
                                    }
                                }}
                            >
                                {tg("button.submit")}
                            </Button>
                        </div>
                    )
                }
                headingDescription={!targets?.submitted ? t("targets.description.how-to") : undefined}
            >
                {targets && targets.submitted && (
                    <div className={"flex gap-3"}>
                        <Text>{tg("label.approval-state")}</Text>
                        {targets.approved ? (
                            <Badge color={"green"}>{tg("label.approved")}</Badge>
                        ) : (
                            <Badge color={"yellow"}>{tg("label.pending")}</Badge>
                        )}
                    </div>
                )}

                <Form onSubmit={addForm.handleSubmit}>
                    <Table dense={true} className={"max-h-screen"}>
                        <TableHead>
                            <TableRow
                                key={"header"}
                                className={"sticky top-0 z-10 !border-b dark:border-zinc-800 dark:bg-zinc-900"}
                            >
                                <TableHeader>{t("targets.label.target-type")}</TableHeader>
                                <TableHeader>{t("targets.label.target-data")}</TableHeader>
                                <TableHeader>{t("targets.label.priority")}</TableHeader>
                                <TableHeader>{tg("label.comment")}</TableHeader>
                                {targets && !targets.submitted && (
                                    <TableHeader className={"w-0"}>
                                        <span className={"sr-only"}>{tg("accessibility.actions")}</span>
                                    </TableHeader>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {entries.map((entry) => (
                                <TableRow key={entry.uuid}>
                                    <TableCell>{entry.type}</TableCell>
                                    <TableCell>{entry.data}</TableCell>
                                    <TableCell>{entry.priority}</TableCell>
                                    <TableCell>{entry.comment}</TableCell>
                                    {targets && !targets.submitted && (
                                        <TableCell>
                                            <Dropdown>
                                                <DropdownButton plain={true}>
                                                    <EllipsisVerticalIcon />
                                                </DropdownButton>
                                                <DropdownMenu anchor={"bottom end"}>
                                                    <DropdownItem onClick={() => deleteEntry(entry.uuid)}>
                                                        {tg("button.delete")}
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>
                                        </TableCell>
                                    )}
                                </TableRow>
                            ))}

                            {!targets?.submitted && (
                                <TableRow key={"add"}>
                                    <TableCell>
                                        <addForm.Field name={"type"}>
                                            {(fieldApi) => (
                                                <Field className={"flex h-full items-start"}>
                                                    <Listbox
                                                        autoFocus={true}
                                                        value={fieldApi.state.value}
                                                        onChange={(e: AddPentestTargetRequest["kind"]) =>
                                                            fieldApi.handleChange(e)
                                                        }
                                                    >
                                                        <ListboxOption value={"Ip"}>{tg("label.ip")}</ListboxOption>
                                                        <ListboxOption value={"Domain"}>
                                                            {tg("label.domain")}
                                                        </ListboxOption>
                                                    </Listbox>
                                                </Field>
                                            )}
                                        </addForm.Field>
                                    </TableCell>
                                    <TableCell>
                                        <addForm.Field
                                            name={"data"}
                                            validators={{
                                                // eslint-disable-next-line
                                                onBlur: ({ value, fieldApi }) => {
                                                    const type = fieldApi.form.getFieldValue("type");

                                                    if (type === "Ip") {
                                                        if (
                                                            !ipaddr.isValid(value) &&
                                                            !ipaddr.isValidCIDR(value) &&
                                                            value !== ""
                                                        ) {
                                                            return t("targets.error.invalid-ip");
                                                        }
                                                    } else {
                                                        if (value !== "" && !validateDomain(value)) {
                                                            return t("targets.error.invalid-domain");
                                                        }
                                                    }

                                                    return undefined;
                                                },
                                            }}
                                        >
                                            {(fieldApi) => (
                                                <Field>
                                                    <Input
                                                        autoComplete={"off"}
                                                        required={true}
                                                        invalid={fieldApi.state.meta.errors.length > 0}
                                                        value={fieldApi.state.value}
                                                        onChange={(e) => fieldApi.handleChange(e.target.value)}
                                                        onBlur={fieldApi.handleBlur}
                                                    />
                                                    {fieldApi.state.meta.errors.map((err) => (
                                                        <ErrorMessage>{err}</ErrorMessage>
                                                    ))}
                                                </Field>
                                            )}
                                        </addForm.Field>
                                    </TableCell>
                                    <TableCell>
                                        <addForm.Field name={"priority"}>
                                            {(fieldApi) => (
                                                <Field>
                                                    <Listbox
                                                        value={fieldApi.state.value}
                                                        onChange={(e: PentestTargetsPriority) =>
                                                            fieldApi.handleChange(e)
                                                        }
                                                    >
                                                        <ListboxOption value={"Excluded"}>
                                                            {tg("label.excluded")}
                                                        </ListboxOption>
                                                        <ListboxOption value={"Low"}>
                                                            {tg("label.priority-low")}
                                                        </ListboxOption>
                                                        <ListboxOption value={"Normal"}>
                                                            {tg("label.priority-normal")}
                                                        </ListboxOption>
                                                        <ListboxOption value={"High"}>
                                                            {tg("label.priority-high")}
                                                        </ListboxOption>
                                                    </Listbox>
                                                </Field>
                                            )}
                                        </addForm.Field>
                                    </TableCell>
                                    <TableCell>
                                        <addForm.Field name={"comment"}>
                                            {(fieldApi) => (
                                                <Field>
                                                    <Input
                                                        value={fieldApi.state.value}
                                                        onChange={(e) => fieldApi.handleChange(e.target.value)}
                                                    />
                                                </Field>
                                            )}
                                        </addForm.Field>
                                    </TableCell>
                                    <TableCell>
                                        <Button color={"blue"} type={"submit"}>
                                            <PlusIcon className={"fill-white"} />
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </Form>
            </HeadingLayout>

            {openDirty && (
                <Dialog open={openDirty} onClose={() => setOpenDirty(false)}>
                    <DialogTitle>{t("targets.heading.cant-submit")}</DialogTitle>
                    <DialogDescription>{t("targets.description.dirty")}</DialogDescription>
                    <DialogActions>
                        <Button onClick={() => setOpenDirty(false)}>{tg("button.close")}</Button>
                    </DialogActions>
                </Dialog>
            )}

            {openSubmit && (
                <Dialog open={openSubmit} onClose={() => setOpenSubmit(false)}>
                    <DialogTitle>{t("targets.heading.submit-dialog")}</DialogTitle>
                    <DialogDescription>{t("targets.description.submit-dialog")}</DialogDescription>
                    <DialogActions>
                        <Button onClick={() => setOpenSubmit(false)}>{tg("button.no")}</Button>
                        <Button
                            onClick={() => {
                                submitTargets();
                                setOpenSubmit(false);
                            }}
                            color={"blue"}
                        >
                            {tg("button.yes")}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </div>
    );
}

export const Route = createFileRoute("/_customer/c/forms/tasks/$taskId/target")({
    component: PentestTargets,
});
