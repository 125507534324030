import { createFileRoute } from "@tanstack/react-router";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import PENTEST_PROJECT_CONTEXT from "src/context/pentest-project-internal";
import BookedPentestModules from "src/components/customer/booked-pentest-modules";
import { Subheading } from "src/components/base/heading";
import ContactCard from "src/components/contact-card";
import {
    FullPentestProject,
    InternalGetReadyTasksResponse,
    InternalReadyTask,
    InternalUser,
    SimpleUser,
} from "src/api/generated";
import INTERNAL_TASK_CONTEXT from "src/context/tasks-internal";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "src/components/base/table";
import { sortDates } from "src/utils/sorter";
import { displayDeadline } from "src/utils/tasks";
import { Text } from "src/components/base/text";
import { Dropdown, DropdownButton, DropdownItem, DropdownLabel, DropdownMenu } from "src/components/base/dropdown";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { Dialog, DialogActions, DialogBody, DialogTitle } from "src/components/base/dialog";
import Form from "src/components/base/form";
import { Field, Label } from "src/components/base/fieldset";
import { Combobox, ComboboxOption } from "src/components/base/combobox";
import { Api } from "src/api/api";
import { toast } from "react-toastify";
import { Avatar } from "src/components/base/avatar";
import { Button } from "src/components/base/button";
import DialogDelete from "src/components/dialogs/dialog-delete";

/**
 * The properties for {@link PentestProjectGeneral}
 */
export type PentestProjectGeneralProps = {};

/**
 * The general view for a pentest project
 */
function PentestProjectGeneral(props: PentestProjectGeneralProps) {
    const [t] = useTranslation();
    // @ts-ignore correct type
    const { obj: o, reset } = React.useContext(PENTEST_PROJECT_CONTEXT);
    const obj = o as FullPentestProject;
    const { tasks: ta } = React.useContext(INTERNAL_TASK_CONTEXT);
    const tasks = ta as InternalGetReadyTasksResponse;

    const [internalList, setInternalList] = React.useState<Array<InternalUser>>([]);
    const [changeTl, setChangeTl] = React.useState(false);
    const [techLead, setTechLead] = React.useState<SimpleUser | null>(null);
    const [techLeadQuery, setTechLeadQuery] = React.useState("");
    const [techAssistants] = React.useState<Array<SimpleUser>>(obj.technical_assistants);
    const [changeMA, setChangeMA] = React.useState(false);
    const [deleteMA, setDeleteMA] = React.useState(false);
    const [mgmtAssistant, setMgmtAssistant] = React.useState<SimpleUser | null>(null);
    const [mgmtAssistQuery, setMgmtAssistQuery] = React.useState("");

    const pentestTasks = tasks.my_tasks.filter((task: InternalReadyTask) => {
        if (
            task.data.kind === "ScheduleKickOffV0" ||
            task.data.kind === "EnterKickOffResultsV0" ||
            task.data.kind === "UploadPentestKickoffPresentationV0" ||
            task.data.kind === "UploadPentestDialogPresentationV0" ||
            task.data.kind === "EnterDialogResultsV0" ||
            task.data.kind === "UploadPentestMgmtPresentationPresentationV0" ||
            task.data.kind === "EnterMgmtPresentationResultsV0" ||
            task.data.kind === "UploadPentestReportV0" ||
            task.data.kind === "ScheduleKickOffV1" ||
            task.data.kind === "EnterKickOffResultsV1" ||
            task.data.kind === "ScheduleDialogV0" ||
            task.data.kind === "EnterDialogResultsV1" ||
            task.data.kind === "ScheduleDebriefV0" ||
            task.data.kind === "EnterDebriefResultsV0" ||
            task.data.kind === "ScheduleMgmtPresentationV0" ||
            task.data.kind === "EnterMgmtPresentationResultsV1"
        ) {
            return task.data.project === obj.uuid;
        } else if (
            task.data.kind === "InviteCustomerUserV0" ||
            task.data.kind === "InviteITDialogV0" ||
            task.data.kind === "InviteITDebriefV0" ||
            task.data.kind === "InviteMgmtPresentationV0" ||
            task.data.kind === "PentestExternalImportDataV0" ||
            task.data.kind === "PentestExternalStartInitalScanV0" ||
            task.data.kind === "PentestExternalConfirmFoundV0" ||
            task.data.kind === "PentestExternalScanShodanV0" ||
            task.data.kind === "PentestExternalCreateServicesV0" ||
            task.data.kind === "PentestExternalTestSslV0" ||
            task.data.kind === "PentestExternalTestCommonV0" ||
            task.data.kind === "PentestExternalTestExoticV0" ||
            task.data.kind === "PentestExternalRunKrakenAssiV0" ||
            task.data.kind === "PentestExternalAddHttpFindingsV0"
        ) {
            return task.project?.uuid === obj.uuid;
        }
    });

    /**
     * Api call to change Technical Lead of Pentest
     */
    const changeTechLead = () => {
        techLead &&
            Api.internal.projects.pentest.update(obj.uuid, { technical_lead: techLead.uuid }).then((res) => {
                res.match(
                    (res) => {
                        if (res.result === "Ok") {
                            reset();
                        } else {
                            toast.error(res.error.technical_lead);
                        }
                    },
                    (err) => toast.error(err.message),
                );
            });
    };

    /**
     * Api call to change Management Assistant of Pentest
     */
    const changeMgmtAssistant = () => {
        mgmtAssistant &&
            Api.internal.projects.pentest.update(obj.uuid, { management_assistant: mgmtAssistant.uuid }).then((res) => {
                res.match(
                    (res) => {
                        if (res.result === "Ok") {
                            reset();
                        } else {
                            toast.error(res.error.management_assistant);
                        }
                    },
                    (err) => toast.error(err.message),
                );
            });
    };

    useEffect(() => {
        Api.internal.internalUsers.all().then((res) =>
            res.match(
                (list) => setInternalList(list.list),
                (err) => toast.error(err.message),
            ),
        );
    }, []);

    const techs = internalList.filter((c) => c.groups.technical);
    const filteredTechLeads =
        techLeadQuery === ""
            ? techs.filter((user) => !techAssistants.some((u) => u.uuid === user.uuid))
            : techs
                  .filter((user) => !techAssistants.some((u) => u.uuid === user.uuid))
                  .filter((user) => user.display_name.toLowerCase().includes(techLeadQuery.toLowerCase()));

    const pms = internalList.filter((c) => c.groups.project_management);
    const filteredMgmtAssist =
        mgmtAssistQuery !== ""
            ? pms
                  .filter((user) => user.uuid !== obj.customer.project_manager.uuid)
                  .filter((user) => user.display_name.toLowerCase().includes(mgmtAssistQuery.toLowerCase()))
            : pms.filter((user) => user.uuid !== obj.customer.project_manager.uuid);
    /*
      const [svg, setSvg] = React.useState("");
      React.useEffect(() => {
          fetch(`/api/frontend/v1/common/debug/project/${obj.uuid}/task-graph`)
              .then((resp) => resp.text())
              .then(setSvg);
      }, [obj.uuid]);
      <div dangerouslySetInnerHTML={{ __html: svg }} />
      */

    return (
        <div className={"grid grid-cols-6 gap-6"}>
            <div className={"col-span-6"}>
                <BookedPentestModules modules={obj.modules} />
            </div>

            <div className={"col-span-6 mb-6 flex flex-col gap-3"}>
                <Subheading>{t("label.tasks")}</Subheading>
                {pentestTasks.length === 0 ? (
                    <Text>{t("internal.pentest-project.description.no-tasks-left")}</Text>
                ) : (
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableHeader>{t("label.due-until")}</TableHeader>
                                <TableHeader>{t("label.task")}</TableHeader>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {pentestTasks
                                .sort((a: InternalReadyTask, b: InternalReadyTask) =>
                                    sortDates(
                                        a.deadline ? new Date(a.deadline) : undefined,
                                        b.deadline ? new Date(b.deadline) : undefined,
                                    ),
                                )
                                .map((task: InternalReadyTask) =>
                                    task.data.kind === "ScheduleKickOffV0" ? (
                                        <TableRow
                                            key={task.uuid}
                                            href={"/i/forms/tasks/$taskId/schedule-kickoff-v0"}
                                            params={{ taskId: task.uuid }}
                                        >
                                            <TableCell></TableCell>
                                            <TableCell className={"text-balance"}>
                                                {t("tasks.label.schedule-kickoff")}
                                            </TableCell>
                                        </TableRow>
                                    ) : task.data.kind === "EnterKickOffResultsV0" ? (
                                        <TableRow
                                            key={task.uuid}
                                            href={"/i/forms/tasks/$taskId/enter-kickoff-results-v0"}
                                            params={{ taskId: task.uuid }}
                                        >
                                            <TableCell title={task.deadline && task.deadline.toLocaleString()}>
                                                {task.deadline && displayDeadline(new Date(task.deadline))}
                                            </TableCell>
                                            <TableCell className={"text-balance"}>
                                                {t("tasks.label.enter-kickoff-results")}
                                            </TableCell>
                                        </TableRow>
                                    ) : task.data.kind === "UploadPentestKickoffPresentationV0" ? (
                                        <TableRow
                                            key={task.uuid}
                                            href={"/i/forms/tasks/$taskId/upload-kickoff-presentation"}
                                            params={{ taskId: task.uuid }}
                                        >
                                            <TableCell title={task.deadline && task.deadline.toLocaleString()}>
                                                {task.deadline && displayDeadline(new Date(task.deadline))}
                                            </TableCell>
                                            <TableCell className={"text-balance"}>
                                                {t("tasks.label.upload-kickoff-presentation")}
                                            </TableCell>
                                        </TableRow>
                                    ) : task.data.kind === "UploadPentestDialogPresentationV0" ? (
                                        <TableRow
                                            key={task.uuid}
                                            href={"/i/forms/tasks/$taskId/upload-dialog-presentation"}
                                            params={{ taskId: task.uuid }}
                                        >
                                            <TableCell title={task.deadline && task.deadline.toLocaleString()}>
                                                {task.deadline && displayDeadline(new Date(task.deadline))}
                                            </TableCell>
                                            <TableCell className={"text-balance"}>
                                                {t("tasks.label.upload-dialog-presentation")}
                                            </TableCell>
                                        </TableRow>
                                    ) : task.data.kind === "EnterDialogResultsV0" ? (
                                        <TableRow
                                            key={task.uuid}
                                            href={"/i/forms/tasks/$taskId/enter-dialog-results-v0"}
                                            params={{ taskId: task.uuid }}
                                        >
                                            <TableCell title={task.deadline && task.deadline.toLocaleString()}>
                                                {task.deadline && displayDeadline(new Date(task.deadline))}
                                            </TableCell>
                                            <TableCell className={"text-balance"}>
                                                {t("tasks.label.enter-dialog-results")}
                                            </TableCell>
                                        </TableRow>
                                    ) : task.data.kind === "UploadPentestMgmtPresentationPresentationV0" ? (
                                        <TableRow
                                            key={task.uuid}
                                            href={"/i/forms/tasks/$taskId/upload-mgmt-presentation"}
                                            params={{ taskId: task.uuid }}
                                        >
                                            <TableCell title={task.deadline && task.deadline.toLocaleString()}>
                                                {task.deadline && displayDeadline(new Date(task.deadline))}
                                            </TableCell>
                                            <TableCell className={"text-balance"}>
                                                {t("tasks.label.upload-mgmt-presentation")}
                                            </TableCell>
                                        </TableRow>
                                    ) : task.data.kind === "EnterMgmtPresentationResultsV0" ? (
                                        <TableRow
                                            key={task.uuid}
                                            href={"/i/forms/tasks/$taskId/enter-mgmt-results-v0"}
                                            params={{ taskId: task.uuid }}
                                        >
                                            <TableCell title={task.deadline && task.deadline.toLocaleString()}>
                                                {task.deadline && displayDeadline(new Date(task.deadline))}
                                            </TableCell>
                                            <TableCell className={"text-balance"}>
                                                {t("tasks.label.enter-mgmt-presentation-results")}
                                            </TableCell>
                                        </TableRow>
                                    ) : task.data.kind === "UploadPentestReportV0" ? (
                                        <TableRow
                                            key={task.uuid}
                                            href={"/i/forms/tasks/$taskId/upload-pentest-report"}
                                            params={{ taskId: task.uuid }}
                                        >
                                            <TableCell title={task.deadline && task.deadline.toLocaleString()}>
                                                {task.deadline && displayDeadline(new Date(task.deadline))}
                                            </TableCell>
                                            <TableCell className={"text-balance"}>
                                                {t("tasks.label.upload-pentest-report")}
                                            </TableCell>
                                        </TableRow>
                                    ) : task.data.kind === "InviteCustomerUserV0" ? (
                                        <TableRow
                                            key={task.uuid}
                                            href={"/i/forms/tasks/$taskId/invite-customer-user-v0"}
                                            params={{ taskId: task.uuid }}
                                        >
                                            <TableCell
                                                title={task.deadline && new Date(task.deadline).toLocaleString()}
                                            >
                                                {task.deadline && displayDeadline(new Date(task.deadline))}
                                            </TableCell>
                                            <TableCell className={"text-balance"}>
                                                {t("tasks.label.invite-customer-user")}
                                            </TableCell>
                                        </TableRow>
                                    ) : task.data.kind === "InviteITDialogV0" ? (
                                        <TableRow
                                            key={task.uuid}
                                            href={"/i/forms/tasks/$taskId/invite-dialog-v0"}
                                            params={{ taskId: task.uuid }}
                                        >
                                            <TableCell
                                                title={task.deadline && new Date(task.deadline).toLocaleString()}
                                            >
                                                {task.deadline && displayDeadline(new Date(task.deadline))}
                                            </TableCell>
                                            <TableCell className={"text-balance"}>
                                                {t("tasks.label.invite-it-dialog")}
                                            </TableCell>
                                        </TableRow>
                                    ) : task.data.kind === "InviteITDebriefV0" ? (
                                        <TableRow
                                            key={task.uuid}
                                            href={"/i/forms/tasks/$taskId/invite-debrief-v0"}
                                            params={{ taskId: task.uuid }}
                                        >
                                            <TableCell
                                                title={task.deadline && new Date(task.deadline).toLocaleString()}
                                            >
                                                {task.deadline && displayDeadline(new Date(task.deadline))}
                                            </TableCell>
                                            <TableCell className={"text-balance"}>
                                                {t("tasks.label.invite-it-debrief")}
                                            </TableCell>
                                        </TableRow>
                                    ) : task.data.kind === "InviteMgmtPresentationV0" ? (
                                        <TableRow
                                            key={task.uuid}
                                            href={"/i/forms/tasks/$taskId/invite-mgmt-presentation-v0"}
                                            params={{ taskId: task.uuid }}
                                        >
                                            <TableCell
                                                title={task.deadline && new Date(task.deadline).toLocaleString()}
                                            >
                                                {task.deadline && displayDeadline(new Date(task.deadline))}
                                            </TableCell>
                                            <TableCell className={"text-balance"}>
                                                {t("tasks.label.invite-mgmt-presentation")}
                                            </TableCell>
                                        </TableRow>
                                    ) : task.data.kind === "PentestExternalImportDataV0" ? (
                                        <TableRow
                                            key={task.uuid}
                                            href={"/i/forms/tasks/$taskId/import-data-v0"}
                                            params={{ taskId: task.uuid }}
                                        >
                                            <TableCell
                                                title={task.deadline && new Date(task.deadline).toLocaleString()}
                                            >
                                                {task.deadline && displayDeadline(new Date(task.deadline))}
                                            </TableCell>
                                            <TableCell className={"text-balance"}>
                                                {t("tasks.label.import-data")}
                                            </TableCell>
                                        </TableRow>
                                    ) : task.data.kind === "PentestExternalStartInitalScanV0" ? (
                                        <TableRow
                                            key={task.uuid}
                                            href={"/i/forms/tasks/$taskId/start-initial-scan-v0"}
                                            params={{ taskId: task.uuid }}
                                        >
                                            <TableCell
                                                title={task.deadline && new Date(task.deadline).toLocaleString()}
                                            >
                                                {task.deadline && displayDeadline(new Date(task.deadline))}
                                            </TableCell>
                                            <TableCell className={"text-balance"}>
                                                {t("tasks.label.start-initial-scan")}
                                            </TableCell>
                                        </TableRow>
                                    ) : task.data.kind === "PentestExternalConfirmFoundV0" ? (
                                        <TableRow
                                            key={task.uuid}
                                            href={"/i/forms/tasks/$taskId/confirm-found-v0"}
                                            params={{ taskId: task.uuid }}
                                        >
                                            <TableCell
                                                title={task.deadline && new Date(task.deadline).toLocaleString()}
                                            >
                                                {task.deadline && displayDeadline(new Date(task.deadline))}
                                            </TableCell>
                                            <TableCell className={"text-balance"}>
                                                {t("tasks.label.confirm-found")}
                                            </TableCell>
                                        </TableRow>
                                    ) : task.data.kind === "PentestExternalScanShodanV0" ? (
                                        <TableRow
                                            key={task.uuid}
                                            href={"/i/forms/tasks/$taskId/scan-shodan-v0"}
                                            params={{ taskId: task.uuid }}
                                        >
                                            <TableCell
                                                title={task.deadline && new Date(task.deadline).toLocaleString()}
                                            >
                                                {task.deadline && displayDeadline(new Date(task.deadline))}
                                            </TableCell>
                                            <TableCell className={"text-balance"}>
                                                {t("tasks.label.scan-shodan")}
                                            </TableCell>
                                        </TableRow>
                                    ) : task.data.kind === "PentestExternalCreateServicesV0" ? (
                                        <TableRow
                                            key={task.uuid}
                                            href={"/i/forms/tasks/$taskId/create-services-v0"}
                                            params={{ taskId: task.uuid }}
                                        >
                                            <TableCell
                                                title={task.deadline && new Date(task.deadline).toLocaleString()}
                                            >
                                                {task.deadline && displayDeadline(new Date(task.deadline))}
                                            </TableCell>
                                            <TableCell className={"text-balance"}>
                                                {t("tasks.label.create-services")}
                                            </TableCell>
                                        </TableRow>
                                    ) : task.data.kind === "PentestExternalTestSslV0" ? (
                                        <TableRow
                                            key={task.uuid}
                                            href={"/i/forms/tasks/$taskId/test-ssl-v0"}
                                            params={{ taskId: task.uuid }}
                                        >
                                            <TableCell
                                                title={task.deadline && new Date(task.deadline).toLocaleString()}
                                            >
                                                {task.deadline && displayDeadline(new Date(task.deadline))}
                                            </TableCell>
                                            <TableCell className={"text-balance"}>
                                                {t("tasks.label.test-ssl")}
                                            </TableCell>
                                        </TableRow>
                                    ) : task.data.kind === "PentestExternalTestCommonV0" ? (
                                        <TableRow
                                            key={task.uuid}
                                            href={"/i/forms/tasks/$taskId/test-common-v0"}
                                            params={{ taskId: task.uuid }}
                                        >
                                            <TableCell
                                                title={task.deadline && new Date(task.deadline).toLocaleString()}
                                            >
                                                {task.deadline && displayDeadline(new Date(task.deadline))}
                                            </TableCell>
                                            <TableCell className={"text-balance"}>
                                                {t("tasks.label.test-common")}
                                            </TableCell>
                                        </TableRow>
                                    ) : task.data.kind === "PentestExternalTestExoticV0" ? (
                                        <TableRow
                                            key={task.uuid}
                                            href={"/i/forms/tasks/$taskId/test-exotic-v0"}
                                            params={{ taskId: task.uuid }}
                                        >
                                            <TableCell
                                                title={task.deadline && new Date(task.deadline).toLocaleString()}
                                            >
                                                {task.deadline && displayDeadline(new Date(task.deadline))}
                                            </TableCell>
                                            <TableCell className={"text-balance"}>
                                                {t("tasks.label.test-exotic")}
                                            </TableCell>
                                        </TableRow>
                                    ) : task.data.kind === "PentestExternalRunKrakenAssiV0" ? (
                                        <TableRow
                                            key={task.uuid}
                                            href={"/i/forms/tasks/$taskId/run-kraken-assi-v0"}
                                            params={{ taskId: task.uuid }}
                                        >
                                            <TableCell
                                                title={task.deadline && new Date(task.deadline).toLocaleString()}
                                            >
                                                {task.deadline && displayDeadline(new Date(task.deadline))}
                                            </TableCell>
                                            <TableCell className={"text-balance"}>
                                                {t("tasks.label.run-kraken-assi")}
                                            </TableCell>
                                        </TableRow>
                                    ) : task.data.kind === "PentestExternalAddHttpFindingsV0" ? (
                                        <TableRow
                                            key={task.uuid}
                                            href={"/i/forms/tasks/$taskId/add-http-findings-v0"}
                                            params={{ taskId: task.uuid }}
                                        >
                                            <TableCell
                                                title={task.deadline && new Date(task.deadline).toLocaleString()}
                                            >
                                                {task.deadline && displayDeadline(new Date(task.deadline))}
                                            </TableCell>
                                            <TableCell className={"text-balance"}>
                                                {t("tasks.label.add-http-findings")}
                                            </TableCell>
                                        </TableRow>
                                    ) : task.data.kind === "ScheduleKickOffV1" ? (
                                        <TableRow
                                            key={task.uuid}
                                            href={"/i/forms/tasks/$taskId/schedule-kickoff-v1"}
                                            params={{ taskId: task.uuid }}
                                        >
                                            <TableCell></TableCell>
                                            <TableCell className={"text-balance"}>
                                                {t("tasks.label.schedule-kickoff")}
                                            </TableCell>
                                        </TableRow>
                                    ) : task.data.kind === "EnterKickOffResultsV1" ? (
                                        <TableRow
                                            key={task.uuid}
                                            href={"/i/forms/tasks/$taskId/enter-kickoff-results-v1"}
                                            params={{ taskId: task.uuid }}
                                        >
                                            <TableCell
                                                title={task.deadline && new Date(task.deadline).toLocaleString()}
                                            >
                                                {task.deadline && displayDeadline(new Date(task.deadline))}
                                            </TableCell>
                                            <TableCell className={"text-balance"}>
                                                {t("tasks.label.enter-kickoff-results")}
                                            </TableCell>
                                        </TableRow>
                                    ) : task.data.kind === "ScheduleDebriefV0" ? (
                                        <TableRow
                                            key={task.uuid}
                                            href={"/i/forms/tasks/$taskId/schedule-debrief-v0"}
                                            params={{ taskId: task.uuid }}
                                        >
                                            <TableCell></TableCell>
                                            <TableCell className={"text-balance"}>
                                                {t("tasks.label.schedule-debrief")}
                                            </TableCell>
                                        </TableRow>
                                    ) : task.data.kind === "EnterDialogResultsV1" ? (
                                        <TableRow
                                            key={task.uuid}
                                            href={"/i/forms/tasks/$taskId/enter-dialog-results-v1"}
                                            params={{ taskId: task.uuid }}
                                        >
                                            <TableCell
                                                title={task.deadline && new Date(task.deadline).toLocaleString()}
                                            >
                                                {task.deadline && displayDeadline(new Date(task.deadline))}
                                            </TableCell>
                                            <TableCell className={"text-balance"}>
                                                {t("tasks.label.enter-dialog-results")}
                                            </TableCell>
                                        </TableRow>
                                    ) : task.data.kind === "EnterDebriefResultsV0" ? (
                                        <TableRow
                                            key={task.uuid}
                                            href={"/i/forms/tasks/$taskId/enter-debrief-results-v0"}
                                            params={{ taskId: task.uuid }}
                                        >
                                            <TableCell
                                                title={task.deadline && new Date(task.deadline).toLocaleString()}
                                            >
                                                {task.deadline && displayDeadline(new Date(task.deadline))}
                                            </TableCell>
                                            <TableCell className={"text-balance"}>
                                                {t("tasks.label.enter-debrief-results")}
                                            </TableCell>
                                        </TableRow>
                                    ) : task.data.kind === "ScheduleDialogV0" ? (
                                        <TableRow
                                            key={task.uuid}
                                            href={"/i/forms/tasks/$taskId/schedule-dialog-v0"}
                                            params={{ taskId: task.uuid }}
                                        >
                                            <TableCell
                                                title={task.deadline && new Date(task.deadline).toLocaleString()}
                                            >
                                                {task.deadline && displayDeadline(new Date(task.deadline))}
                                            </TableCell>
                                            <TableCell className={"text-balance"}>
                                                {t("tasks.label.schedule-dialog")}
                                            </TableCell>
                                        </TableRow>
                                    ) : task.data.kind === "EnterMgmtPresentationResultsV1" ? (
                                        <TableRow
                                            key={task.uuid}
                                            href={"/i/forms/tasks/$taskId/enter-mgmt-results-v1"}
                                            params={{ taskId: task.uuid }}
                                        >
                                            <TableCell
                                                title={task.deadline && new Date(task.deadline).toLocaleString()}
                                            >
                                                {task.deadline && displayDeadline(new Date(task.deadline))}
                                            </TableCell>
                                            <TableCell className={"text-balance"}>
                                                {t("tasks.label.enter-mgmt-presentation-results")}
                                            </TableCell>
                                        </TableRow>
                                    ) : task.data.kind === "ScheduleMgmtPresentationV0" ? (
                                        <TableRow
                                            key={task.uuid}
                                            href={"/i/forms/tasks/$taskId/schedule-mgmt-presentation-v0"}
                                            params={{ taskId: task.uuid }}
                                        >
                                            <TableCell
                                                title={task.deadline && new Date(task.deadline).toLocaleString()}
                                            >
                                                {task.deadline && displayDeadline(new Date(task.deadline))}
                                            </TableCell>
                                            <TableCell className={"text-balance"}>
                                                {t("tasks.label.schedule-mgmt-presentation")}
                                            </TableCell>
                                        </TableRow>
                                    ) : (
                                        <div>{task.data.kind}</div>
                                    ),
                                )}
                        </TableBody>
                    </Table>
                )}
            </div>

            <div className={"col-span-6 row-span-3 grid grid-cols-1 grid-rows-3 gap-9 md:grid-cols-3 md:grid-rows-1"}>
                <div className={"flex flex-col gap-3 "}>
                    <Subheading>{t("label.project-manager")}</Subheading>
                    <ContactCard
                        contact={{
                            ...obj.customer.project_manager,
                            name: obj.customer.project_manager.display_name,
                            mail: obj.customer.project_manager.mail,
                            user: obj.customer.project_manager,
                        }}
                    />
                </div>
                <div className={"flex flex-col gap-3"}>
                    <Subheading>{t("label.tech-lead")}</Subheading>
                    <ContactCard
                        key={obj.technical_lead.uuid}
                        contact={{
                            ...obj.technical_lead,
                            name: obj.technical_lead.display_name,
                            mail: obj.technical_lead.mail,
                            user: obj.technical_lead,
                        }}
                        actions={
                            <Dropdown>
                                <DropdownButton plain={true}>
                                    <span className={"sr-only"}>{t("accessibility.actions")}</span>
                                    <EllipsisVerticalIcon />
                                </DropdownButton>
                                <DropdownMenu anchor={"bottom end"}>
                                    <DropdownItem
                                        onClick={() => {
                                            setChangeTl(true);
                                            setTechLead(obj.technical_lead);
                                        }}
                                    >
                                        <DropdownLabel>{t("button.change")}</DropdownLabel>
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        }
                    />
                </div>
                {obj.management_assistant ? (
                    <div className={"flex flex-col gap-3"}>
                        <Subheading>{t("label.mgmt-assistant")}</Subheading>
                        <ContactCard
                            key={obj.management_assistant.uuid}
                            contact={{
                                ...obj.management_assistant,
                                name: obj.management_assistant.display_name,
                                mail: obj.management_assistant.mail,
                                user: obj.management_assistant,
                            }}
                            actions={
                                <Dropdown>
                                    <DropdownButton plain={true}>
                                        <span className={"sr-only"}>{t("accessibility.actions")}</span>
                                        <EllipsisVerticalIcon />
                                    </DropdownButton>
                                    <DropdownMenu anchor={"bottom end"}>
                                        <DropdownItem
                                            onClick={() => {
                                                setChangeMA(true);
                                                if (obj.management_assistant) {
                                                    setMgmtAssistant(obj.management_assistant);
                                                }
                                            }}
                                        >
                                            <DropdownLabel>{t("button.change")}</DropdownLabel>
                                        </DropdownItem>
                                        <DropdownItem
                                            onClick={() => {
                                                setDeleteMA(true);
                                                if (obj.management_assistant) {
                                                    setMgmtAssistant(obj.management_assistant);
                                                }
                                            }}
                                        >
                                            <DropdownLabel>{t("button.delete")}</DropdownLabel>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            }
                        />
                    </div>
                ) : (
                    <div className={"flex -translate-y-1.5 flex-col gap-3"}>
                        <div className={"flex flex-row items-center gap-3 "}>
                            <Subheading>{t("label.mgmt-assistant")}</Subheading>
                            <Dropdown>
                                <DropdownButton plain={true}>
                                    <EllipsisVerticalIcon />
                                </DropdownButton>
                                <DropdownMenu anchor={"bottom end"}>
                                    <DropdownItem
                                        onClick={() => {
                                            setChangeMA(true);
                                        }}
                                    >
                                        {t("button.add")}
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                        <Text>{t("pentest.description.no-ma")}</Text>
                    </div>
                )}
            </div>

            <div className={"col-span-6 flex flex-col gap-3 "}>
                <div className={"flex flex-row items-center gap-3 "}>
                    <Subheading>{t("label.tech-assistants")}</Subheading>
                    <Dropdown>
                        <DropdownButton plain={true}>
                            <EllipsisVerticalIcon />
                        </DropdownButton>
                        <DropdownMenu anchor={"bottom end"}>
                            <DropdownItem
                                href={"/i/pentests/$projectId/general/edit-ta"}
                                params={{ projectId: obj.uuid }}
                            >
                                {t("internal.pentest-project.button.edit")}
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </div>
                {/*Layout for max 3 TA*/}
                {obj.technical_assistants.length === 0 ? (
                    <Text>{t("pentest.description.no-ta")}</Text>
                ) : (
                    <div
                        className={
                            "grid grid-cols-1 grid-rows-3 gap-6 md:grid-cols-2 md:grid-rows-2 2xl:grid-cols-3 2xl:grid-rows-1"
                        }
                    >
                        {obj.technical_assistants.map((assistant) => (
                            <ContactCard
                                contact={{
                                    ...assistant,
                                    name: assistant.display_name,
                                    mail: assistant.mail,
                                    user: assistant,
                                }}
                            />
                        ))}
                    </div>
                )}
            </div>

            {changeMA && (
                <Dialog
                    open={true}
                    onClose={() => {
                        setChangeMA(false);
                        setMgmtAssistant(null);
                    }}
                >
                    <Form
                        onSubmit={() => {
                            changeMgmtAssistant();
                            setChangeMA(false);
                            setMgmtAssistant(null);
                        }}
                    >
                        <DialogTitle>{t("internal.pentest-project.heading.change-ma")}</DialogTitle>
                        <DialogBody>
                            <Field>
                                <Combobox
                                    value={mgmtAssistant}
                                    onChange={(ma) => {
                                        setMgmtAssistant(ma);
                                    }}
                                    onClose={() => setMgmtAssistQuery("")}
                                    queryDisplay={(ma) => (ma ? ma.display_name : "")}
                                    onQueryChange={(query) => setMgmtAssistQuery(query)}
                                >
                                    {filteredMgmtAssist.map((ma) => (
                                        <ComboboxOption key={ma.uuid} value={ma}>
                                            <Label className={"flex items-center gap-2"}>
                                                <Avatar
                                                    square={true}
                                                    className={"size-5"}
                                                    initials={ma.display_name
                                                        .split(" ")
                                                        .map((c) => c[0])
                                                        .join("")}
                                                />
                                                <span>{ma.display_name}</span>
                                            </Label>
                                        </ComboboxOption>
                                    ))}
                                </Combobox>
                            </Field>
                        </DialogBody>
                        <DialogActions>
                            <Button type={"submit"} color={"blue"}>
                                {t("button.change")}
                            </Button>
                        </DialogActions>
                    </Form>
                </Dialog>
            )}

            {deleteMA && (
                <DialogDelete
                    title={t("dialog.heading.delete-ma", {
                        name: mgmtAssistant !== null ? mgmtAssistant.display_name : "",
                    })}
                    onClose={() => {
                        setDeleteMA(false);
                        setMgmtAssistant(null);
                    }}
                    onNo={() => {
                        setDeleteMA(false);
                        setMgmtAssistant(null);
                    }}
                    onYes={() => {
                        mgmtAssistant &&
                            Api.internal.projects.pentest
                                .update(obj.uuid, { management_assistant: null })
                                .then((res) => {
                                    res.match(
                                        (res) => {
                                            if (res.result === "Ok") {
                                                reset();
                                                setDeleteMA(false);
                                                setMgmtAssistant(null);
                                            } else {
                                                toast.error(res.error.management_assistant);
                                            }
                                        },
                                        (err) => toast.error(err.message),
                                    );
                                });
                    }}
                />
            )}

            {changeTl && (
                <Dialog
                    open={true}
                    onClose={() => {
                        setChangeTl(false);
                        setTechLead(null);
                    }}
                >
                    <Form
                        onSubmit={() => {
                            changeTechLead();
                            setChangeTl(false);
                            setTechLead(null);
                        }}
                    >
                        <DialogTitle>{t("internal.pentest-project.heading.change-tl")}</DialogTitle>
                        <DialogBody>
                            <Field>
                                <Combobox
                                    value={techLead}
                                    onChange={(tl) => setTechLead(tl)}
                                    onClose={() => setTechLeadQuery("")}
                                    queryDisplay={(tl) => (tl ? tl.display_name : "")}
                                    onQueryChange={(query) => setTechLeadQuery(query)}
                                >
                                    {filteredTechLeads.map((tl) => (
                                        <ComboboxOption key={tl.uuid} value={tl}>
                                            <Label className={"flex items-center gap-2"}>
                                                <Avatar
                                                    square={true}
                                                    className={"size-5"}
                                                    initials={tl.display_name
                                                        .split(" ")
                                                        .map((c) => c[0])
                                                        .join("")}
                                                />
                                                <span>{tl.display_name}</span>
                                            </Label>
                                        </ComboboxOption>
                                    ))}
                                </Combobox>
                            </Field>
                        </DialogBody>
                        <DialogActions>
                            <Button type={"submit"} color={"blue"}>
                                {t("button.change")}
                            </Button>
                        </DialogActions>
                    </Form>
                </Dialog>
            )}
        </div>
    );
}

export const Route = createFileRoute("/_internal/i/pentests/$projectId/_pentest/general/")({
    component: PentestProjectGeneral,
});
