/* tslint:disable */
/* eslint-disable */
/**
 * Unnamed API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiErrorResponse,
  GetCreateCampaignOptionsResponse,
  ListForCampaign,
  ListForScenario,
  SingleUuid,
} from '../models/index';

export interface GetScenariosRequest {
    id: number;
}

export interface RunCreateCampaignRequest {
    body: any | null;
}

export interface RunGenerateAttackStatusRequest {
    body: any | null;
}

export interface RunGeneratePresentationRequest {
    body: any | null;
}

export interface RunGenerateReportRequest {
    body: any | null;
}

export interface RunGenerateTrainingStatusRequest {
    body: any | null;
}

export interface RunImportEmailRequest {
    body: any | null;
}

export interface RunImportEmployeeRequest {
    body: any | null;
}

/**
 * 
 */
export class LucyAutomationApi extends runtime.BaseAPI {

    /**
     * Retrieves all campaigns from lucy
     * Retrieves all campaigns from lucy
     */
    async getCampaignsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListForCampaign>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/internal/phishing/lucy-automation/campaigns`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Retrieves all campaigns from lucy
     * Retrieves all campaigns from lucy
     */
    async getCampaigns(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListForCampaign> {
        const response = await this.getCampaignsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Retrieves the options to display in the form for the `create_campaign.py` script
     * Retrieves the options to display in the form for the `create_campaign.py` script
     */
    async getCreateCampaignOptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCreateCampaignOptionsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/internal/phishing/lucy-automation/get-create-campaign-options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Retrieves the options to display in the form for the `create_campaign.py` script
     * Retrieves the options to display in the form for the `create_campaign.py` script
     */
    async getCreateCampaignOptions(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCreateCampaignOptionsResponse> {
        const response = await this.getCreateCampaignOptionsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getScenariosRaw(requestParameters: GetScenariosRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListForScenario>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getScenarios().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/frontend/v1/internal/phishing/lucy-automation/campaigns/{id}/scenarios`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async getScenarios(requestParameters: GetScenariosRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListForScenario> {
        const response = await this.getScenariosRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Runs the `create_campaign.py` script
     * Runs the `create_campaign.py` script
     */
    async runCreateCampaignRaw(requestParameters: RunCreateCampaignRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SingleUuid>> {
        if (requestParameters['body'] == null) {
            throw new runtime.RequiredError(
                'body',
                'Required parameter "body" was null or undefined when calling runCreateCampaign().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/internal/phishing/lucy-automation/run-create-campaign`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Runs the `create_campaign.py` script
     * Runs the `create_campaign.py` script
     */
    async runCreateCampaign(requestParameters: RunCreateCampaignRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SingleUuid> {
        const response = await this.runCreateCampaignRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Runs the `generate_attack_status.py` script
     * Runs the `generate_attack_status.py` script
     */
    async runGenerateAttackStatusRaw(requestParameters: RunGenerateAttackStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SingleUuid>> {
        if (requestParameters['body'] == null) {
            throw new runtime.RequiredError(
                'body',
                'Required parameter "body" was null or undefined when calling runGenerateAttackStatus().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/internal/phishing/lucy-automation/run-generate-attack-status`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Runs the `generate_attack_status.py` script
     * Runs the `generate_attack_status.py` script
     */
    async runGenerateAttackStatus(requestParameters: RunGenerateAttackStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SingleUuid> {
        const response = await this.runGenerateAttackStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Runs the `generate_presentation.py` script
     * Runs the `generate_presentation.py` script
     */
    async runGeneratePresentationRaw(requestParameters: RunGeneratePresentationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SingleUuid>> {
        if (requestParameters['body'] == null) {
            throw new runtime.RequiredError(
                'body',
                'Required parameter "body" was null or undefined when calling runGeneratePresentation().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/internal/phishing/lucy-automation/run-generate-presentation`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Runs the `generate_presentation.py` script
     * Runs the `generate_presentation.py` script
     */
    async runGeneratePresentation(requestParameters: RunGeneratePresentationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SingleUuid> {
        const response = await this.runGeneratePresentationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Runs the `generate_report.py` script
     * Runs the `generate_report.py` script
     */
    async runGenerateReportRaw(requestParameters: RunGenerateReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SingleUuid>> {
        if (requestParameters['body'] == null) {
            throw new runtime.RequiredError(
                'body',
                'Required parameter "body" was null or undefined when calling runGenerateReport().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/internal/phishing/lucy-automation/run-generate-report`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Runs the `generate_report.py` script
     * Runs the `generate_report.py` script
     */
    async runGenerateReport(requestParameters: RunGenerateReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SingleUuid> {
        const response = await this.runGenerateReportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Runs the `generate_training_status.py` script
     * Runs the `generate_training_status.py` script
     */
    async runGenerateTrainingStatusRaw(requestParameters: RunGenerateTrainingStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SingleUuid>> {
        if (requestParameters['body'] == null) {
            throw new runtime.RequiredError(
                'body',
                'Required parameter "body" was null or undefined when calling runGenerateTrainingStatus().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/internal/phishing/lucy-automation/run-generate-training-status`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Runs the `generate_training_status.py` script
     * Runs the `generate_training_status.py` script
     */
    async runGenerateTrainingStatus(requestParameters: RunGenerateTrainingStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SingleUuid> {
        const response = await this.runGenerateTrainingStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Runs the `import_email.py` script
     * Runs the `import_email.py` script
     */
    async runImportEmailRaw(requestParameters: RunImportEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SingleUuid>> {
        if (requestParameters['body'] == null) {
            throw new runtime.RequiredError(
                'body',
                'Required parameter "body" was null or undefined when calling runImportEmail().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/internal/phishing/lucy-automation/run-import-email`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Runs the `import_email.py` script
     * Runs the `import_email.py` script
     */
    async runImportEmail(requestParameters: RunImportEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SingleUuid> {
        const response = await this.runImportEmailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Runs the `import_employee.py` script
     * Runs the `import_employee.py` script
     */
    async runImportEmployeeRaw(requestParameters: RunImportEmployeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SingleUuid>> {
        if (requestParameters['body'] == null) {
            throw new runtime.RequiredError(
                'body',
                'Required parameter "body" was null or undefined when calling runImportEmployee().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/frontend/v1/internal/phishing/lucy-automation/run-import-employee`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Runs the `import_employee.py` script
     * Runs the `import_employee.py` script
     */
    async runImportEmployee(requestParameters: RunImportEmployeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SingleUuid> {
        const response = await this.runImportEmployeeRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
