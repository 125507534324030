import { createFileRoute } from "@tanstack/react-router";
import React from "react";
import { useTranslation } from "react-i18next";
import CUSTOMER_CONTEXT from "src/context/customer";
import ContactCard from "src/components/contact-card";
import { Input } from "src/components/base/input";
import { Field, FieldGroup, Fieldset, Label } from "src/components/base/fieldset";
import { Dropdown, DropdownButton, DropdownItem, DropdownLabel, DropdownMenu } from "src/components/base/dropdown";
import { ClipboardIcon, EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { Api } from "src/api/api";
import { toast } from "react-toastify";
import DialogDelete from "src/components/dialogs/dialog-delete";
import { FullCustomerContact } from "src/api/generated";
import { Dialog } from "src/components/base/dialog";
import { Button } from "src/components/base/button";
import DialogCreateContact from "src/components/dialogs/internal/dialog-create-contact";
import DialogEditContact from "src/components/dialogs/internal/dialog-edit-contact";
import USER_CONTEXT from "src/context/user";

/**
 * The properties for {@link CustomerContacts}
 */
export type CustomerContactsProps = {};

/**
 * The contacts of a customer
 */
function CustomerContacts(props: CustomerContactsProps) {
    const [t] = useTranslation();
    const { customer, reset } = React.useContext(CUSTOMER_CONTEXT);
    const { user } = React.useContext(USER_CONTEXT);

    const [query, setQuery] = React.useState("");
    const [deleteContact, setDeleteContact] = React.useState<FullCustomerContact>();
    const [editContact, setEditContact] = React.useState<FullCustomerContact>();
    const [inviteLink, setInviteLink] = React.useState<string>();
    const [createContact, setCreateContact] = React.useState(false);

    const filteredContacts =
        query === ""
            ? customer.contacts
            : customer.contacts.filter((c) => c.name.toLowerCase().includes(query.toLowerCase()));

    return (
        <div className={"flex flex-col gap-6"}>
            <div className={"flex flex-row items-end justify-between"}>
                <Field className={"max-w-xs"}>
                    <Label>{t("label.search")}</Label>
                    <Input value={query} onChange={(e) => setQuery(e.target.value)} />
                </Field>
                <Button onClick={() => setCreateContact(true)}>{t("internal.customer.button.create-contact")}</Button>
            </div>

            <div className={"grid gap-6 sm:grid-cols-2 xl:grid-cols-3"}>
                {filteredContacts.map((contact) => (
                    <ContactCard
                        key={contact.uuid}
                        contact={{ ...contact, mail: contact.email, invited: contact.invite, phone: contact.phone }}
                        actions={
                            <Dropdown>
                                <DropdownButton plain={true}>
                                    <span className={"sr-only"}>{t("accessibility.actions")}</span>
                                    <EllipsisVerticalIcon />
                                </DropdownButton>
                                <DropdownMenu anchor={"bottom end"}>
                                    {!contact.user && (
                                        <DropdownItem
                                            onClick={async () => {
                                                const res = await Api.internal.customers.contacts.invite(contact.uuid);

                                                res.match(
                                                    (res) => {
                                                        if (res.result === "Ok") {
                                                            setInviteLink(res.value.link);
                                                        } else {
                                                            if (res.error.mail) {
                                                                toast.error(t("error.mail-in-use"));
                                                            }
                                                        }
                                                    },
                                                    (err) => toast.error(err.message),
                                                );
                                            }}
                                        >
                                            <DropdownLabel>{t("button.create-invite")}</DropdownLabel>
                                        </DropdownItem>
                                    )}
                                    {contact.user &&
                                        user.permissions.role === "Internal" &&
                                        user.permissions.groups.support && (
                                            <DropdownItem
                                                onClick={() => {
                                                    contact.user &&
                                                        Api.internal.customers.contacts
                                                            .resetCredentials(contact.uuid)
                                                            .then((res) =>
                                                                res.match(
                                                                    (res) => {
                                                                        if (res.result === "Ok") {
                                                                            toast.success(
                                                                                "Reset link was send via mail",
                                                                            );
                                                                        }
                                                                    },
                                                                    (err) => toast.error(err.message),
                                                                ),
                                                            );
                                                }}
                                            >
                                                <DropdownLabel>{t("button.reset-credentials")}</DropdownLabel>
                                            </DropdownItem>
                                        )}
                                    {!contact.user && (
                                        <DropdownItem onClick={() => setEditContact(contact)}>
                                            <DropdownLabel>{t("button.edit")}</DropdownLabel>
                                        </DropdownItem>
                                    )}
                                    <DropdownItem onClick={() => setDeleteContact(contact)}>
                                        <DropdownLabel>{t("button.delete")}</DropdownLabel>
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        }
                    />
                ))}
            </div>

            {createContact && (
                <DialogCreateContact
                    open={true}
                    onClose={() => setCreateContact(false)}
                    onSubmit={() => {
                        reset();
                        setCreateContact(false);
                    }}
                    customerUuid={customer.uuid}
                />
            )}

            {editContact && (
                <DialogEditContact
                    open={true}
                    contact={editContact}
                    onSubmit={() => {
                        reset();
                        setEditContact(undefined);
                    }}
                    onClose={() => setEditContact(undefined)}
                />
            )}

            {deleteContact && (
                <DialogDelete
                    title={t("internal.customer.heading.delete-contact", { name: deleteContact.name })}
                    description={deleteContact.user ? t("dialog.delete.description") : ""}
                    onClose={() => setDeleteContact(undefined)}
                    onNo={() => setDeleteContact(undefined)}
                    onYes={() => {
                        deleteContact &&
                            Api.internal.customers.contacts.delete(deleteContact.uuid).then((res) =>
                                res.match(
                                    (res) => {
                                        if (res.result === "Ok") {
                                            reset();
                                            setDeleteContact(undefined);
                                        } else {
                                            if (res.error.last_project_contact) {
                                                toast.error(t("internal.customer.toast.last-project-contact"));
                                            }
                                        }
                                    },
                                    (err) => toast.error(err.message),
                                ),
                            );
                    }}
                />
            )}

            {inviteLink && (
                <Dialog open={true} onClose={() => setInviteLink(undefined)}>
                    <Fieldset>
                        <FieldGroup>
                            <Field>
                                <Label>{t("label.invite-link")}</Label>
                                <span className={"mt-3 flex gap-3"}>
                                    <Input value={inviteLink} contentEditable={false} />
                                    <Button
                                        plain={true}
                                        onClick={() => {
                                            navigator.clipboard.writeText(inviteLink).then(() => {
                                                toast.success(t("toast.copied-to-clipboard"));
                                                setInviteLink(undefined);
                                            });
                                        }}
                                    >
                                        <ClipboardIcon />
                                    </Button>
                                </span>
                            </Field>
                        </FieldGroup>
                    </Fieldset>
                </Dialog>
            )}
        </div>
    );
}

export const Route = createFileRoute("/_internal/i/customers/$customerId/_customer/contacts")({
    component: CustomerContacts,
});
