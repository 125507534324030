import { createFileRoute } from "@tanstack/react-router";
import { useTranslation } from "react-i18next";
import { Button } from "src/components/base/button";

/**
 * The properties for {@link ScriptsIndex}
 */
export type ScriptsIndexProps = {};

/**
 * List of all available scripts
 */
function ScriptsIndex(props: ScriptsIndexProps) {
    const [t] = useTranslation("internal-scripts-index");

    return (
        <div className={"flex flex-wrap gap-6"}>
            <Button href={"/i/phishing/scripts/create-campaign"}>{t("button.create-campaign")}</Button>
            <Button href={"/i/phishing/scripts/generate-attack-status"}>{t("button.generate-attack-status")}</Button>
            <Button href={"/i/phishing/scripts/generate-training-status"}>
                {t("button.generate-training-status")}
            </Button>
            <Button href={"/i/phishing/scripts/generate-presentation"}>{t("button.generate-presentation")}</Button>
        </div>
    );
}

export const Route = createFileRoute("/_internal/i/phishing/scripts/")({
    component: ScriptsIndex,
});
