import { createFileRoute } from "@tanstack/react-router";

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "@tanstack/react-form";
import { Api } from "src/api/api";
import { toast } from "react-toastify";
import Form from "src/components/base/form";
import { ErrorMessage, Field, FieldGroup, Fieldset, Label, RequiredLabel } from "src/components/base/fieldset";
import { Button } from "src/components/base/button";
import { Input } from "src/components/base/input";
import { Textarea } from "src/components/base/textarea";
import BlockUnsavedChanges from "src/components/base/unsaved-changes";
import HeadingLayout from "src/components/base/heading-layout";

/**
 * The properties for {@link EnterManagementPresentationResultsForm}
 */
export type EnterManagementPresentationResultsFormProps = {};

/**
 * The form to enter dialog results
 */
function EnterManagementPresentationResultsForm(props: EnterManagementPresentationResultsFormProps) {
    const [t] = useTranslation();
    const { taskId } = Route.useParams();

    const form = useForm({
        defaultValues: {
            feedback_date: "",
            upsell_options: "",
            notes: "",
        },
        // eslint-disable-next-line
        onSubmit: async ({ formApi, value }) => {
            const res = await Api.internal.projects.pentest.setManagementPresentationResultsV0(taskId, {
                feedback_date: new Date(value.feedback_date).toISOString(),
                upsale_options: value.upsell_options,
                notes: value.notes,
            });
            res.match(
                (res) => {
                    if (res.result === "Ok") {
                        window.history.back();
                    } else {
                        if (res.error.feedback_date) {
                            formApi.setFieldMeta("feedback_date", (meta) => {
                                meta.errors.push(t("error.date-in-past"));
                                return meta;
                            });
                        }
                    }
                },
                (err) => toast.error(err.message),
            );
        },
    });
    const isDirty = form.useStore((store) => store.isDirty);

    const [customerName, setCustomerName] = React.useState<string>("");

    useEffect(() => {
        Api.internal.tasks.getReady(taskId).then((res) => {
            res.match(
                (res) => {
                    if (res.project) {
                        setCustomerName(res.project.customer.legal_name);
                    }
                },
                (err) => toast.error(err.message),
            );
        });
    }, []);

    return (
        <HeadingLayout
            heading={t("internal.enter-mgmt-presentation-results.heading")}
            headingDescription={t("label.customer") + ": " + customerName}
        >
            <Form onSubmit={form.handleSubmit} className={"max-w-lg"}>
                <Fieldset>
                    <FieldGroup>
                        <form.Field name={"feedback_date"}>
                            {(fieldApi) => (
                                <Field>
                                    <RequiredLabel>{t("label.feedback-date")}</RequiredLabel>
                                    <Input
                                        required={true}
                                        autoFocus={true}
                                        type={"datetime-local"}
                                        value={fieldApi.state.value}
                                        invalid={fieldApi.state.meta.errors.length > 0}
                                        onChange={(e) => fieldApi.handleChange(e.target.value)}
                                    />
                                    {fieldApi.state.meta.errors.map((err) => (
                                        <ErrorMessage>{err}</ErrorMessage>
                                    ))}
                                </Field>
                            )}
                        </form.Field>

                        <form.Field name={"upsell_options"}>
                            {(fieldApi) => (
                                <Field>
                                    <Label>{t("label.upsell-options")}</Label>
                                    <Textarea
                                        value={fieldApi.state.value}
                                        onChange={(e) => fieldApi.handleChange(e.target.value)}
                                    />
                                </Field>
                            )}
                        </form.Field>

                        <form.Field name={"notes"}>
                            {(fieldApi) => (
                                <Field>
                                    <Label>{t("label.notes")}</Label>
                                    <Textarea
                                        value={fieldApi.state.value}
                                        onChange={(e) => fieldApi.handleChange(e.target.value)}
                                    />
                                </Field>
                            )}
                        </form.Field>

                        <Button type={"submit"} color={"blue"}>
                            {t("button.submit")}
                        </Button>
                    </FieldGroup>
                </Fieldset>
            </Form>

            <BlockUnsavedChanges condition={isDirty} />
        </HeadingLayout>
    );
}

export const Route = createFileRoute("/_internal/i/forms/tasks/$taskId/enter-mgmt-results-v0")({
    component: EnterManagementPresentationResultsForm,
});
