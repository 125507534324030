import { createFileRoute } from "@tanstack/react-router";

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Api, UUID } from "src/api/api";
import { Campaign } from "src/api/generated";
import { useForm } from "@tanstack/react-form";
import { toast } from "react-toastify";
import RunScript from "src/components/internal/scripts";
import { ErrorMessage, Field, Label, RequiredLabel } from "src/components/base/fieldset";
import { Combobox, ComboboxOption } from "src/components/base/combobox";
import { Divider } from "src/components/base/divider";
import FileInput from "src/components/base/file-input";
import { Input } from "src/components/base/input";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "src/components/base/table";
import { Button } from "src/components/base/button";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";

/**
 * The properties for {@link ScriptGeneratePresentation}
 */
export type ScriptGeneratePresentationProps = {};

/** Representation of a file */
type DownloadFile = {
    /** name of the file */
    name: string;
    /** UUID for reference in media */
    uuid: UUID;
};

/**
 * The result of the script run
 */
type GeneratePresentationResult = {
    /** List of uuids for plots */
    plots: Array<DownloadFile>;
    /** UUID to the presentation */
    presentation: DownloadFile;
};

/**
 * Run script: Generate presentation
 */
function ScriptGeneratePresentation(props: ScriptGeneratePresentationProps) {
    const [t] = useTranslation("internal-scripts-generate-presentation");
    const [tg] = useTranslation();

    const [runningScript, setRunningScript] = React.useState<UUID>();
    const [campaigns, setCampaigns] = React.useState<Array<Campaign>>([]);

    const [campaignSearch, setCampaignSearch] = React.useState("");

    const form = useForm({
        defaultValues: {
            campaign: null as null | Campaign,
            lookupTable: null as null | File,
            mailColumn: "",
            aggregationColumn: "",
            fallbackGroup: "",
        },
        // eslint-disable-next-line
        onSubmit: async ({ value, formApi }) => {
            if (value.campaign === null) {
                formApi.setFieldMeta("campaign", (meta) => {
                    meta.errors.push(t("error.must-be-filled"));
                    return meta;
                });
                return;
            }

            const res = await Api.internal.lucy.runGeneratePresentation({ campaign: value.campaign.id });
            if (res.isErr) {
                toast.error(res.err.message);
                return;
            }

            setRunningScript(res.ok.uuid);
        },
    });
    const lookupFileGiven = form.useStore((store) => store.values.lookupTable !== null);

    /**
     * Fetch the campaigns from lucy
     */
    const fetchCampaigns = async () => {
        const res = await Api.internal.lucy.getCampaigns();
        if (res.isErr) {
            toast.error(res.err.message);
            return;
        }

        setCampaigns(
            res.ok.list.filter((c) => !c.name.startsWith("[Template]")).filter((c) => !c.name.startsWith("Training")),
        );
    };

    useEffect(() => {
        fetchCampaigns().then();
    }, []);

    const campaignOptions =
        campaignSearch === ""
            ? campaigns
            : campaigns.filter((campaign) => campaign.name.toLowerCase().includes(campaignSearch.toLowerCase()));

    return (
        <RunScript<GeneratePresentationResult>
            runningScript={runningScript}
            setRunningScript={setRunningScript}
            scriptKilled={() => {}}
            renderResult={(res) => (
                <Table dense={true}>
                    <TableHead>
                        <TableRow>
                            <TableHeader>{tg("accessibility.actions")}</TableHeader>
                            <TableHeader className={"w-0"}></TableHeader>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow key={res.presentation.uuid}>
                            <TableCell>{t("button.download-presentation")}</TableCell>
                            <TableCell className={"text-right"}>
                                <Button
                                    href={`/api/frontend/v1/internal/scripts/file/${res.presentation.uuid}`}
                                    plain={true}
                                    target={"_blank"}
                                    download={res.presentation.name}
                                >
                                    <ArrowTopRightOnSquareIcon />
                                </Button>
                            </TableCell>
                        </TableRow>
                        {res.plots.map((plot) => (
                            <TableRow key={plot.uuid}>
                                <TableCell>{t("button.show-plot", { name: plot.name })}</TableCell>
                                <TableCell>
                                    <Button
                                        href={`/api/frontend/v1/internal/scripts/file/${plot.uuid}`}
                                        plain={true}
                                        target={"_blank"}
                                    >
                                        <img
                                            className={"h-fit w-20 max-w-20"}
                                            alt={plot.name}
                                            src={`/api/frontend/v1/internal/scripts/file/${plot.uuid}`}
                                        />
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            )}
            scriptName={t("heading.generate-attack-status")}
            onSubmit={form.handleSubmit}
        >
            <form.Field name={"campaign"}>
                {(fieldApi) => (
                    <Field>
                        <RequiredLabel>{t("label.campaign")}</RequiredLabel>
                        <Combobox
                            invalid={fieldApi.state.meta.errors.length > 0}
                            value={fieldApi.state.value}
                            onChange={fieldApi.handleChange}
                            onClose={() => setCampaignSearch("")}
                            onQueryChange={(query: string) => setCampaignSearch(query)}
                            queryDisplay={(q) => (q ? q.name : "")}
                        >
                            {campaignOptions.map((c) => (
                                <ComboboxOption key={c.id} value={c}>
                                    <Label>{c.name}</Label>
                                </ComboboxOption>
                            ))}
                        </Combobox>
                        {fieldApi.state.meta.errors.map((error) => (
                            <ErrorMessage>{error}</ErrorMessage>
                        ))}
                    </Field>
                )}
            </form.Field>

            <Divider />

            <form.Field name={"lookupTable"}>
                {(fieldApi) => (
                    <Field>
                        <Label>{t("label.lookup-table")}</Label>
                        <FileInput
                            accept={"text/csv"}
                            onChange={(e) => fieldApi.handleChange(e.target.files ? e.target.files[0] : null)}
                        />
                    </Field>
                )}
            </form.Field>

            {lookupFileGiven && (
                <>
                    <form.Field name={"mailColumn"}>
                        {(fieldApi) => (
                            <Field>
                                <RequiredLabel>{t("label.mail-column")}</RequiredLabel>
                                <Input
                                    required={true}
                                    value={fieldApi.state.value}
                                    onChange={(e) => fieldApi.handleChange(e.target.value)}
                                />
                            </Field>
                        )}
                    </form.Field>

                    <form.Field name={"aggregationColumn"}>
                        {(fieldApi) => (
                            <Field>
                                <RequiredLabel>{t("label.aggregation-column")}</RequiredLabel>
                                <Input
                                    required={true}
                                    value={fieldApi.state.value}
                                    onChange={(e) => fieldApi.handleChange(e.target.value)}
                                />
                            </Field>
                        )}
                    </form.Field>

                    <form.Field name={"fallbackGroup"}>
                        {(fieldApi) => (
                            <Field>
                                <RequiredLabel>{t("label.fallback-group")}</RequiredLabel>
                                <Input
                                    required={true}
                                    value={fieldApi.state.value}
                                    onChange={(e) => fieldApi.handleChange(e.target.value)}
                                />
                            </Field>
                        )}
                    </form.Field>
                </>
            )}
        </RunScript>
    );
}

export const Route = createFileRoute("/_internal/i/phishing/scripts/generate-presentation")({
    component: ScriptGeneratePresentation,
});
